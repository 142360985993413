import { Vector } from '../core/Vector';
import { RenderableI } from '../interfaces/Renderable';
import { Colors } from '../render/Colors';
import { AngleUtils } from '../utils/AngleUtils';
import { BaseEntity } from './BaseEntity';
import { EntityType } from './EntityType';

interface StubDef {
  width: number;
  depth: number;
  angle: number;
}

interface LinePathFeature {
  type: 'line-path';
  path: Vector[];
}

interface PolygoneZoneFeature {
  type: 'polygon-zone';
  path: Vector[];
}

interface CircularZoneFeature {
  type: 'circular-zone';
  position: Vector;
  radius: number;
  stubs: StubDef[];
  style?: string;
}

interface DangerAreaFeature {
  type: 'danger-area';
  position: Vector;
  width: number;
  height: number;
}

interface CircularDangerAreaFeature {
  type: 'circular-danger-area';
  position: Vector;
  radius: number;
}

interface TallObstructionFeature {
  type: 'tall-obstruction';
  position: Vector;
}

export type MapFeatureDefinition =
  | LinePathFeature
  | PolygoneZoneFeature
  | CircularZoneFeature
  | DangerAreaFeature
  | CircularDangerAreaFeature
  | TallObstructionFeature;

export class MapFeatureEntity extends BaseEntity implements RenderableI {
  constructor(public def: MapFeatureDefinition) {
    super(EntityType.MapFeature);
  }

  public render(ctx: CanvasRenderingContext2D, scale: number): void {
    ctx.save();
    ctx.scale(scale, scale);

    switch (this.def.type) {
      case 'line-path':
        this.renderLinePath(ctx, this.def);
        break;
      case 'polygon-zone':
        this.renderPolygonZone(ctx, this.def);
        break;
      case 'circular-zone':
        this.renderCircularZone(ctx, this.def);
        break;
      case 'tall-obstruction':
        this.renderTall(ctx, this.def);
        break;
      case 'danger-area':
        this.renderDangerArea(ctx, this.def);
        break;
      case 'circular-danger-area':
        this.renderCircularDangerArea(ctx, this.def);
        break;
      default:
    }

    ctx.restore();
  }

  private renderLinePath(ctx: CanvasRenderingContext2D, feature: LinePathFeature): void {
    const [firstPoint, ...otherPoints] = feature.path;

    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = Colors.mapFeatureOutline;
    ctx.moveTo(...firstPoint);
    otherPoints.forEach((p) => ctx.lineTo(...p));
    ctx.moveTo(...firstPoint);
    ctx.closePath();
    ctx.stroke();
  }

  private renderPolygonZone(ctx: CanvasRenderingContext2D, feature: PolygoneZoneFeature): void {
    const [firstPoint, ...otherPoints] = feature.path;

    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = Colors.mapFeatureOutline;
    ctx.moveTo(...firstPoint);
    otherPoints.forEach((p) => ctx.lineTo(...p));
    ctx.lineTo(...firstPoint);
    ctx.closePath();
    ctx.stroke();
  }

  private renderCircularZone(ctx: CanvasRenderingContext2D, feature: CircularZoneFeature): void {
    ctx.translate(feature.position[0], feature.position[1]);
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = feature.style || Colors.mapFeatureOutline;
    ctx.ellipse(0, 0, feature.radius, feature.radius, 0, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();

    feature.stubs.forEach((stub) => {
      ctx.save();

      ctx.rotate(AngleUtils.toRad(stub.angle - 90));
      ctx.strokeRect(
        feature.radius * Math.cos(stub.width / 2 / feature.radius),
        -stub.width / 2,
        stub.depth,
        stub.width,
      );
      ctx.fillStyle = Colors.background;
      ctx.fillRect(
        feature.radius * Math.cos(stub.width / 2 / feature.radius) - 3,
        -stub.width / 2 + 1,
        stub.depth + 2,
        stub.width - 2,
      );

      ctx.beginPath();
      ctx.setLineDash([5, 5]);
      const a = Math.asin(stub.width / 2 / feature.radius);
      ctx.arc(0, 0, feature.radius, -a, a);
      ctx.moveTo(0, 0);
      ctx.closePath();
      ctx.stroke();

      ctx.restore();
    });
  }

  private renderTall(ctx: CanvasRenderingContext2D, feature: TallObstructionFeature): void {
    ctx.translate(feature.position[0], feature.position[1]);
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = Colors.mapFeatureOutline;
    ctx.moveTo(-6, 0);
    ctx.lineTo(0, -12);
    ctx.lineTo(6, 0);
    ctx.moveTo(-6, 0);
    ctx.closePath();
    ctx.stroke();
  }

  private renderDangerArea(ctx: CanvasRenderingContext2D, feature: DangerAreaFeature): void {
    ctx.translate(feature.position[0], feature.position[1]);
    ctx.fillStyle = Colors.mapFeatureDangerArea;
    ctx.fillRect(0, 0, feature.width, feature.height);
  }

  private renderCircularDangerArea(
    ctx: CanvasRenderingContext2D,
    feature: CircularDangerAreaFeature,
  ): void {
    ctx.translate(feature.position[0], feature.position[1]);
    ctx.beginPath();
    ctx.strokeStyle = Colors.mapFeatureDangerArea;
    ctx.fillStyle = Colors.mapFeatureDangerArea;
    ctx.ellipse(0, 0, feature.radius, feature.radius, 0, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
    ctx.stroke();
  }
}
