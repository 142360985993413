import { Vector } from '../core/Vector';
import { PanelI } from '../interfaces/Panel';
import { Colors } from '../render/Colors';
import { VectorUtils } from '../utils/VectorUtils';

export abstract class BasePanel implements PanelI {
  private position: Vector;

  constructor(private title: string, protected size: Vector, private screenSize: Vector) {
    this.position = this.positioner(screenSize);
  }

  public render(ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.translate(...this.position);

    ctx.fillStyle = Colors.uiPrimary;
    ctx.fillRect(0, 0, this.size[0], 30);
    ctx.fillRect(-2, -2, this.size[0] + 4, this.size[1] + 4);

    ctx.fillStyle = Colors.uiSecondary;
    ctx.fillRect(0, 35, this.size[0], this.size[1] - 35);

    ctx.fillStyle = Colors.uiSecondary;
    ctx.font = '28px courier';
    ctx.fillText(this.title, 10, 25);

    this.renderPanelContent(ctx);
    ctx.restore();
  }

  public inClickRegion(click: Vector): boolean {
    return (
      this.position[0] <= click[0] &&
      click[0] <= this.position[0] + this.size[0] &&
      this.position[1] <= click[1] &&
      click[1] <= this.position[1] + this.size[1]
    );
  }

  public onClick(click: Vector): boolean {
    return this.onInternalClick(VectorUtils.subtract(click, this.position));
  }

  public onScreenResize(screenSize: Vector): void {
    this.screenSize = screenSize;
    this.position = this.positioner(screenSize);
  }

  protected abstract renderPanelContent(ctx: CanvasRenderingContext2D): void;
  protected abstract onInternalClick(click: Vector): boolean;
  protected abstract positioner(screenSize: Vector): Vector;
}
