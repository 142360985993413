import { GameRenderer } from '../render/GameRenderer';
import { MouseManager } from '../input/MouseManager';
import { CanvasNotFoundError, ElementNotCanvasError } from '../errors/RenderingErrors';
import { CanvasUtils } from '../utils/CanvasUtils';
import { Vector } from './Vector';
import { VectorUtils } from '../utils/VectorUtils';
import { KeyboardManager, KeyboardManagerKey, ModifierKeysState } from '../input/KeyboardManager';
import { SceneI } from '../interfaces/Scene';
import { RadarScene, RadarSceneDef } from '../scenes/RadarScene';
import { MenuScene } from '../scenes/MenuScene';
import Stats from 'stats.js';
import { Colors } from '../render/Colors';
import { UIRenderer } from '../render/UIRenderer';
import { debounce } from '../utils/debounce';

export class Engine {
  public timeScale: number = 1;
  public renderer: GameRenderer;
  public uiRenderer: UIRenderer;
  public debugMode: boolean = false;
  public screenSize: Vector;
  public lastLoopTime: number | undefined;
  public tickCounter: number = 0;
  private gameplayCanvas: HTMLCanvasElement;
  private uiCanvas: HTMLCanvasElement;
  private mouseManager: MouseManager;
  private keyboardManager: KeyboardManager;
  private isRunning: boolean = false;
  private timeSinceLastLogicTick: number = 0;
  private gameFrameCounter: number = 0;
  private uiFrameCounter: number = 0;
  private requireUIRender: boolean = true;
  private uiAnimationRequiredTime: number = 0;
  private activeScene: SceneI;
  private stats = new Stats();

  private get msPerTick(): number {
    return 600 / this.timeScale;
  }

  public get modifierKeysState(): ModifierKeysState {
    return this.keyboardManager.modState;
  }

  constructor(gameplaySelector: string, uiSelector: string) {
    this.screenSize = [window.innerWidth * 2, window.innerHeight * 2];

    const gameplayCanvas = document.querySelector(gameplaySelector);
    const uiCanvas = document.querySelector(uiSelector);
    if (!gameplayCanvas) {
      throw new CanvasNotFoundError(gameplaySelector);
    } else if (!uiCanvas) {
      throw new CanvasNotFoundError(uiSelector);
    } else if (!CanvasUtils.elementIsCanvas(gameplayCanvas)) {
      throw new ElementNotCanvasError();
    } else if (!CanvasUtils.elementIsCanvas(uiCanvas)) {
      throw new ElementNotCanvasError();
    }

    this.gameplayCanvas = gameplayCanvas;
    this.uiCanvas = uiCanvas;
    this.renderer = new GameRenderer(this.screenSize, gameplayCanvas);
    this.uiRenderer = new UIRenderer(this.screenSize, uiCanvas);

    window.onresize = debounce(() => {
      this.screenSize = [window.innerWidth * 2, window.innerHeight * 2];

      this.renderer.handleResize(this.screenSize);
      this.uiRenderer.handleResize(this.screenSize);
      if (this.activeScene.onScreenResize) {
        this.activeScene.onScreenResize(this.screenSize);
      }

      this.triggerGameplayRender();
      this.triggerUIRender();
    }, 50);

    this.mouseManager = new MouseManager(
      this,
      window.document,
      this.onClick.bind(this),
      this.onMousePositionUpdate.bind(this),
      this.onPan.bind(this),
    );
    this.keyboardManager = new KeyboardManager(this, window.document, this.onKeyPress.bind(this));

    this.activeScene = new MenuScene();

    // this.toggleDebugMode();

    (window as any).Engine = this;
  }

  public async start(): Promise<void> {
    this.isRunning = true;
    await this.renderer.init();
    window.requestAnimationFrame(this.loop.bind(this));
    console.log('Game started');
  }

  public pause(): void {
    this.isRunning = false;
  }

  public transitionTo(scene: SceneI): void {
    if (this.activeScene.onExit) {
      this.activeScene.onExit();
    }
    this.activeScene = scene;
    if (this.activeScene.onEnter) {
      this.activeScene.onEnter();
    }
  }

  public transitionToRadar(mapDef: RadarSceneDef): RadarScene {
    const radar = new RadarScene(this, mapDef);
    this.transitionTo(radar);
    return radar;
  }

  public queueUIRender(): void {
    this.requireUIRender = true;
  }

  public uiAnimationTime(ms: number): void {
    if (ms > this.uiAnimationRequiredTime) {
      this.uiAnimationRequiredTime = ms;
    }
  }

  private onClick(rawPos: Vector): boolean {
    if (!this.activeScene.onClick) {
      return false;
    }

    const worldPos = this.rawToWorldPosition(rawPos);
    if (this.debugMode) {
      console.log(`Click at: [${worldPos[0]}, ${worldPos[1]}]`);
    }

    this.requireUIRender = true;
    return this.activeScene.onClick(worldPos, rawPos);
  }

  private onMousePositionUpdate(rawPos: Vector | null): void {
    if (!this.activeScene.onMousePositionUpdate) {
      return;
    }

    this.activeScene.onMousePositionUpdate(rawPos ? this.rawToWorldPosition(rawPos) : null);
    this.requireUIRender = true;
  }

  private rawToWorldPosition(rawPos: Vector): Vector {
    return VectorUtils.floor(
      VectorUtils.scale(
        VectorUtils.subtract(rawPos, this.renderer.viewOffset),
        1 / this.renderer.scale,
      ),
    );
  }

  private onPan(diff: Vector): void {
    this.renderer.panView(diff);
  }

  private onKeyPress(key: KeyboardManagerKey): void {
    switch (key) {
      case KeyboardManagerKey.ZoomIn:
        this.renderer.zoomIn();
        break;
      case KeyboardManagerKey.ZoomOut:
        this.renderer.zoomOut();
        break;
      case KeyboardManagerKey.SpeedDown:
        this.timeScale = Math.max(1, this.timeScale / 1.2);
        break;
      case KeyboardManagerKey.SpeedUp:
        this.timeScale = Math.min(1000, this.timeScale * 1.2);
        break;
      case KeyboardManagerKey.DebugToggle:
        this.toggleDebugMode();
        break;
      default:
        if (this.activeScene.onKeyPress) {
          this.activeScene.onKeyPress(key);
        }
    }

    this.requireUIRender = true;
  }

  private loop(currentTime: number): void {
    if (!this.isRunning) {
      return;
    }

    if (this.debugMode) {
      this.stats.begin();
    }

    const delta = currentTime - (this.lastLoopTime || currentTime);
    this.timeSinceLastLogicTick += delta;

    while (this.timeSinceLastLogicTick >= this.msPerTick) {
      this.logicTick();
      this.timeSinceLastLogicTick -= this.msPerTick;
      this.requireUIRender = true;
    }

    this.triggerGameplayRender();
    if (this.requireUIRender || this.uiAnimationRequiredTime > 0) {
      this.triggerUIRender();
      this.requireUIRender = false;
    }
    this.lastLoopTime = currentTime;

    if (this.uiAnimationRequiredTime > 0) {
      this.uiAnimationRequiredTime = Math.max(0, this.uiAnimationRequiredTime - delta);
    }

    if (this.debugMode) {
      this.stats.end();
    }

    window.requestAnimationFrame(this.loop.bind(this));
  }

  private logicTick(): void {
    this.activeScene.tick(this.tickCounter);
    this.tickCounter++;
  }

  private toggleDebugMode(): void {
    const enabling = !this.debugMode;
    this.debugMode = enabling;

    if (enabling) {
      this.stats.showPanel(0);
      this.stats.dom.style.transform = 'scale(1.5)';
      document.body.appendChild(this.stats.dom);
    } else {
      this.stats.dom.remove();
    }
  }

  private engineDebugRender(ctx: CanvasRenderingContext2D): void {
    ctx.fillStyle = Colors.debugText;
    ctx.font = '32px courier';
    ctx.fillText(
      `${this.tickCounter} TC, ${this.gameFrameCounter} GR, ${this.uiFrameCounter} UR`,
      5,
      200,
    );
    ctx.fillText(`${this.renderer.zoomLevel} ZL, ${this.timeScale}x TS`, 5, 235);
  }

  private triggerGameplayRender(): void {
    this.renderer.renderFrame(
      this.activeScene.renderableEntities(),
      this.engineDebugRender.bind(this),
      this.debugMode,
    );
    this.gameFrameCounter++;
  }

  private triggerUIRender(): void {
    this.uiRenderer.renderFrame(this.activeScene.uiRenderableEntities(), this.debugMode);
    this.uiFrameCounter++;
  }
}
