export class FormattingUtils {
  public static padZero(number: number, length: number): string {
    let padded = String(number);

    while (padded.length < length) {
      padded = `0${padded}`;
    }

    return padded;
  }

  public static twoDigitAltitude(altitude: number): string {
    const short = Math.round(altitude / 100);
    if (short < 10) {
      return `0${short}`;
    }

    return String(short);
  }

  public static threeDigitHeading(heading: number): string {
    heading = Math.round(heading);
    if (heading < 10) {
      return `00${heading}`;
    } else if (heading < 100) {
      return `0${heading}`;
    }

    return String(heading);
  }

  public static threeDigitAltitude(altitude: number): string {
    altitude = Math.round(altitude / 100);
    if (altitude < 10) {
      return `00${altitude}`;
    } else if (altitude < 100) {
      return `0${altitude}`;
    }

    return String(altitude);
  }

  public static underscorePadInputNumber(heading: string): string {
    if (heading.length === 0) {
      return `___`;
    } else if (heading.length === 1) {
      return `__${heading}`;
    } else if (heading.length === 2) {
      return `_${heading}`;
    }

    return heading;
  }
}
