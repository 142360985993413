import { Vector } from '../core/Vector';
import { UIRenderableI } from '../interfaces/UIRenderable';

export class UIRenderer {
  private context: CanvasRenderingContext2D;

  constructor(public screenSize: Vector, public canvas: HTMLCanvasElement) {
    this.context = canvas.getContext('2d')!;
    this.setupCanvas();
  }

  public async init(): Promise<void> {
    this.clearCanvas();
  }

  public handleResize(screenSize: Vector): void {
    this.screenSize = screenSize;
    this.setupCanvas();
  }

  public renderFrame(uiRenderables: UIRenderableI[], debugMode: boolean): void {
    this.clearCanvas();

    uiRenderables.forEach((r) => {
      r.render(this.context, this.screenSize);
      if (debugMode && r.debugRender) {
        r.debugRender(this.context, this.screenSize);
      }
    });
  }

  private setupCanvas(): void {
    this.canvas.width = this.screenSize[0];
    this.canvas.height = this.screenSize[1];

    this.clearCanvas();
    console.log(`UI canvas calibration complete. ${this.screenSize[0]}x${this.screenSize[1]}`);
  }

  private clearCanvas(): void {
    const { width, height } = this.context.canvas;
    this.context.clearRect(0, 0, width, height);
  }
}
