import { FormattingUtils } from './FormattingUtils';

export class GameplayUtils {
  public static generateCallsign(): string {
    const companies = ['BA', 'UA', 'FX', 'EK', 'EY', 'SA'];

    return (
      companies[Math.floor(Math.random() * companies.length)] + Math.floor(Math.random() * 999)
    );
  }

  public static tickToTime(n: number): string {
    n += 43200;
    let [year, month, day, hour, minute, second] = [0, 0, 0, 0, 0, 0];

    if (n >= 31104000) {
      const years = Math.floor(n / 31104000);
      year += years;
      n -= years * 31104000;
    }

    if (n >= 2592000) {
      const months = Math.floor(n / 2592000);
      month += months;
      n -= months * 2592000;
    }

    if (n >= 86400) {
      const days = Math.floor(n / 86400);
      day += days;
      n -= days * 86400;
    }

    if (n >= 3600) {
      const hours = Math.floor(n / 3600);
      hour += hours;
      n -= hours * 3600;
    }

    if (n >= 60) {
      const minutes = Math.floor(n / 60);
      minute += minutes;
      n -= minutes * 60;
    }

    second += n;

    return `${FormattingUtils.padZero(hour, 2)}${FormattingUtils.padZero(
      minute,
      2,
    )}/${FormattingUtils.padZero(second, 2)}`;
  }
}
