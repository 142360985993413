import { RenderableI } from '../interfaces/Renderable';
import { Vector } from '../core/Vector';
import { FixableI } from '../interfaces/Fixable';
import { Colors } from '../render/Colors';
import { BaseEntity } from './BaseEntity';
import { EntityType } from './EntityType';
import { CanvasUtils } from '../utils/CanvasUtils';
import { ClickableI } from '../interfaces/Clickable';
import { RadarScene } from '../scenes/RadarScene';

export interface FixDefinition {
  position: Vector;
  identifier: string;
  type: 'fix' | 'vor';
}

export class FixEntity extends BaseEntity implements RenderableI, FixableI, ClickableI {
  private CLICK_RANGE = 50;
  private position: Vector;
  private identifier: string;
  private fixType: 'fix' | 'vor';

  constructor(public scene: RadarScene, def: FixDefinition) {
    super(EntityType.Fix);
    this.fixType = def.type;
    this.position = def.position;
    this.identifier = def.identifier;
  }

  public render(ctx: CanvasRenderingContext2D, scale: number): void {
    ctx.fillStyle = Colors.fix;
    ctx.strokeStyle = Colors.fix;
    if (
      this.scene.mousePosition &&
      this.inClickRegion(this.scene.mousePosition) &&
      this.scene.clickableEntities().includes(this)
    ) {
      ctx.strokeStyle = Colors.fixHover;
      ctx.fillStyle = Colors.fixHover;
    } else {
      ctx.fillStyle = Colors.fix;
      ctx.strokeStyle = Colors.fix;
    }

    ctx.save();
    ctx.scale(scale, scale);
    ctx.translate(...this.position);
    if (this.fixType === 'vor') {
      ctx.fillRect(-3, -3, 6, 6);
      CanvasUtils.drawRegularPolygon(ctx, [0, 0], 6, 13, ctx.strokeStyle, 2);
    } else {
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.moveTo(-8, 8);
      ctx.lineTo(0, -8);
      ctx.lineTo(8, 8);
      ctx.closePath();
      ctx.stroke();
      ctx.fill();
    }

    ctx.font = '20px courier';
    ctx.textAlign = 'center';
    ctx.fillText(this.identifier, 0, 30);

    ctx.restore();
  }

  public debugRender(ctx: CanvasRenderingContext2D, scale: number): void {
    const [xC, yC] = this.position;

    ctx.save();
    ctx.scale(scale, scale);
    ctx.translate(xC, yC);

    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = Colors.debugText;
    ctx.ellipse(0, 0, this.CLICK_RANGE, this.CLICK_RANGE, 0, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();

    ctx.restore();
  }

  public getFixPosition(): Vector {
    return this.position;
  }

  public getFixIdentifier(): string {
    return this.identifier;
  }

  public inClickRegion(click: Vector): boolean {
    return (
      Math.sqrt((click[0] - this.position[0]) ** 2 + (click[1] - this.position[1]) ** 2) <
      this.CLICK_RANGE
    );
  }

  public onClick(): boolean {
    this.scene.textCommandController.setLastToken(this.identifier);
    return true;
  }
}
