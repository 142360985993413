export const debounce = (func: Function, wait: number) => {
  let timeout: number;

  return (...args: any[]) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
