import { RunwayDefinition, RunwayEntity } from '../entities/RunwayEntity';
import { AircraftEntity } from '../entities/AircraftEntity';
import { SceneI } from '../interfaces/Scene';
import { MapFeatureEntity, MapFeatureDefinition } from '../entities/MapFeatureEntity';
import { RenderableI } from '../interfaces/Renderable';
import { ClickableI } from '../interfaces/Clickable';
import { Colors } from '../render/Colors';
import { SeparationController } from '../controllers/SeparationController';
import { KeyboardManagerKey } from '../input/KeyboardManager';
import { Vector } from '../core/Vector';
import { ScoreController } from '../controllers/ScoreController';
import { RadioController } from '../controllers/RadioController';
import { SpawnController } from '../controllers/SpawnController';
import { UIRenderableI } from '../interfaces/UIRenderable';
import { GameplayUtils } from '../utils/GameplayUtils';
import { FixDefinition, FixEntity } from '../entities/FixEntity';
import { StarDefinition, StarEntity } from '../entities/StarEntity';
import { Dictionary } from '../lib/Dictionary';
import { FixableI } from '../interfaces/Fixable';
import { PanelI } from '../interfaces/Panel';
import { InboundAircraftPanel } from '../panels/InboundAircraftPanel';
import { OutboundAircraftPanel } from '../panels/OutboundAircraftPanel';
import { Engine } from '../core/Engine';
import { TextCommandController } from '../controllers/TextCommandController';
import { AircraftGoal } from '../interfaces/AircraftGoal';
import { RoutingInstruction } from '../interfaces/RoutingInstruction';

export interface RadarSceneDef {
  range: number;
  runways: RunwayDefinition[];
  fixes: FixDefinition[];
  stars: StarDefinition[];
  features: MapFeatureDefinition[];
}

export class RadarScene implements SceneI, UIRenderableI {
  public range: number;
  public aircrafts: AircraftEntity[];
  public inboundAircrafts: AircraftEntity[];
  public outboundAircrafts: AircraftEntity[];
  public runways: RunwayEntity[];
  public fixes: FixEntity[];
  public stars: StarEntity[];
  public mousePosition: Vector | null;
  public radioController: RadioController;
  public scoreController: ScoreController;
  public textCommandController: TextCommandController;
  public fixableMap: Dictionary<FixableI> = {};
  public starMap: Dictionary<StarEntity> = {};
  public runwayMap: Dictionary<RunwayEntity> = {};

  private separationController: SeparationController;
  private spawnController: SpawnController;
  private panels: PanelI[];
  private features: MapFeatureEntity[];
  private _focussedAircraft: AircraftEntity | null = null;

  constructor(public engine: Engine, mapDef: RadarSceneDef) {
    this.scoreController = new ScoreController(this);
    this.spawnController = new SpawnController(this);
    this.separationController = new SeparationController(this);
    this.radioController = new RadioController(this);
    this.panels = [new InboundAircraftPanel(this), new OutboundAircraftPanel(this)];
    this.textCommandController = new TextCommandController(this);

    this.mousePosition = null;

    this.range = mapDef.range;
    this.features = mapDef.features.map((def) => new MapFeatureEntity(def));
    this.runways = mapDef.runways.map((def) => {
      const runway = new RunwayEntity(this, def);
      this.runwayMap[def.identifier] = runway;
      return runway;
    });
    this.fixes = mapDef.fixes.map((def) => {
      const fix = new FixEntity(this, def);
      this.fixableMap[def.identifier] = fix;
      return fix;
    });
    this.stars = mapDef.stars.map((def) => {
      const points = def.fixes.map((identifier) => this.fixableMap[identifier]);
      const star = new StarEntity(this, def, points);
      this.starMap[def.identifier] = star;
      return star;
    });

    this.aircrafts = [];
    this.inboundAircrafts = [];
    this.outboundAircrafts = [];
  }

  public onEnter(): void {
    this.textCommandController.renderInput();
  }

  public onExit(): void {
    this.textCommandController.destroyInput();
  }

  public set focussedAircraft(a: AircraftEntity | null) {
    this._focussedAircraft = a;
    if (a) {
      this.textCommandController.setInputValue(`${a.callsign} `);
    } else {
      this.textCommandController.setInputValue('');
    }
  }

  public get focussedAircraft(): AircraftEntity | null {
    return this._focussedAircraft;
  }

  public renderableEntities(): RenderableI[] {
    return [
      ...this.features,
      ...this.runways,
      ...this.fixes,
      ...this.stars,
      this.separationController,
      ...this.aircrafts,
    ];
  }

  public uiRenderableEntities(): UIRenderableI[] {
    return [this, this.scoreController, this.radioController, ...this.panels];
  }

  public clickableEntities(): ClickableI[] {
    if (this.textCommandController.openNode) {
      if (this.textCommandController.openNode === 'set-routing') {
        return [...this.aircrafts, ...this.fixes];
      } else if (this.textCommandController.openNode === 'set-localizer') {
        return [...this.runways, ...this.aircrafts];
      } else if (this.textCommandController.openNode === 'set-star') {
        return this.aircrafts;
      }
    }

    return this.aircrafts;
  }

  public render(ctx: CanvasRenderingContext2D, screenSize: Vector): void {
    ctx.fillStyle = Colors.uiPrimary;
    ctx.font = '32px courier';
    ctx.textAlign = 'left';
    ctx.fillText(GameplayUtils.tickToTime(this.engine.tickCounter), 15, 40);
  }

  public debugRender(ctx: CanvasRenderingContext2D): void {
    ctx.fillStyle = Colors.debugText;
    ctx.font = '32px courier';
    ctx.fillText(`${this.aircrafts.length} aircraft(s)`, 5, 270);
  }

  public tick(n: number): void {
    this.aircrafts.forEach((a) => a.tick(n));
    this.separationController.tick(n);
    this.spawnController.tick(n);
  }

  public onClick(worldPos: Vector, screenPos: Vector): boolean {
    const panel = this.panels.find((p) => p.inClickRegion(screenPos));
    if (panel) {
      return panel.onClick(screenPos);
    }

    if (!(window as any).map) {
      (window as any).map = [];
    }

    (window as any).map.push(worldPos);
    console.log(worldPos);

    const entity = this.clickableEntities().find((a) => a.inClickRegion(worldPos));

    let returnValue = false;

    if (entity) {
      returnValue = entity.onClick();
    }

    if (this.focussedAircraft && !entity) {
      this.focussedAircraft = null;
    }

    return returnValue;
  }

  public onKeyPress(key: KeyboardManagerKey): void {
    switch (key) {
      case KeyboardManagerKey.Escape:
        this.focussedAircraft = null;
        break;
      default:
    }
  }

  public onMousePositionUpdate(position: Vector | null): void {
    this.mousePosition = position;
  }

  public onScreenResize(screenSize: Vector): void {
    this.panels.map((p) => p.onScreenResize(screenSize));
  }

  public addAircraft(
    callsign: string,
    position: Vector,
    heading: number,
    speed: number,
    altitude: number,
    routing: RoutingInstruction,
    goal: AircraftGoal,
  ): AircraftEntity {
    const aircraft = new AircraftEntity(
      this,
      callsign,
      position,
      heading,
      speed,
      altitude,
      routing,
      goal,
    );
    this.aircrafts.push(aircraft);
    if (goal.type === 'inbound') {
      this.inboundAircrafts.push(aircraft);
    } else if (goal.type === 'outbound') {
      this.outboundAircrafts.push(aircraft);
    }
    return aircraft;
  }

  public removeAircraft(aircraft: AircraftEntity): void {
    if (this.focussedAircraft === aircraft) {
      this.focussedAircraft = null;
    }

    if (this.textCommandController.draftCommand.aircraft === aircraft) {
      this.textCommandController.setInputValue('');
    }

    if (aircraft.goal.type === 'inbound') {
      this.inboundAircrafts.splice(this.inboundAircrafts.indexOf(aircraft), 1);
    } else if (aircraft.goal.type === 'outbound') {
      this.outboundAircrafts.splice(this.outboundAircrafts.indexOf(aircraft), 1);
    }

    this.aircrafts.splice(this.aircrafts.indexOf(aircraft), 1);
  }

  public findAircraftByCallsign(callsign: string): AircraftEntity | null {
    if (callsign.length === 0) {
      return null;
    }
    return this.aircrafts.find((a) => a.callsign === callsign) || null;
  }
}
