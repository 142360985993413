import { AircraftEntity } from '../entities/AircraftEntity';
import { RenderableI } from '../interfaces/Renderable';
import { TickableI } from '../interfaces/Tickable';
import { Colors } from '../render/Colors';
import { RadarScene } from '../scenes/RadarScene';
import { CanvasUtils } from '../utils/CanvasUtils';
import { VectorUtils } from '../utils/VectorUtils';

enum SeparationNotificationStatus {
  Warn,
  Fail,
}

interface SeparationViolation {
  aircrafts: [AircraftEntity, AircraftEntity];
  distance: number;
  status: SeparationNotificationStatus;
  startedAt: number;
}

export class SeparationController implements TickableI, RenderableI {
  private HOZ_SEPARATION_WARN = 120;
  private HOZ_SEPARATION_FAIL = 70;
  private VERT_SEPARATION = 1000;

  private separationViolations: Map<string, SeparationViolation> = new Map();

  constructor(private scene: RadarScene) {}

  public tick(n: number): void {
    const aircrafts = this.scene.aircrafts;
    const uncheckedAircraft = [...aircrafts];

    const staleViolations = new Set(this.separationViolations.keys());

    while (uncheckedAircraft.length > 2) {
      const a = uncheckedAircraft.shift()!;
      for (const b of uncheckedAircraft) {
        const pairKey = `${a.uuid}:${b.uuid}`;
        const exisitingViolation = this.separationViolations.get(pairKey);
        staleViolations.delete(pairKey);

        const failedVertSeparation = Math.abs(a.altitude - b.altitude) < this.VERT_SEPARATION;
        const distance = VectorUtils.distance(a.position, b.position);
        if (failedVertSeparation && distance < this.HOZ_SEPARATION_WARN) {
          const status =
            distance < this.HOZ_SEPARATION_FAIL
              ? SeparationNotificationStatus.Fail
              : SeparationNotificationStatus.Warn;
          if (exisitingViolation) {
            if (
              status !== exisitingViolation.status &&
              status === SeparationNotificationStatus.Fail
            ) {
              this.scene.scoreController.seperationViolations++;
            }
            this.separationViolations.set(pairKey, {
              ...exisitingViolation,
              distance,
              status,
            });
          } else {
            if (status === SeparationNotificationStatus.Fail) {
              this.scene.scoreController.seperationViolations++;
            }
            this.separationViolations.set(pairKey, {
              aircrafts: [a, b],
              distance,
              status,
              startedAt: n,
            });
          }
        } else if (exisitingViolation) {
          this.separationViolations.delete(pairKey);
        }
      }
    }

    staleViolations.forEach((k) => this.separationViolations.delete(k));
  }

  public render(ctx: CanvasRenderingContext2D, scale: number): void {
    if (this.separationViolations.size === 0) {
      return;
    }

    ctx.save();
    ctx.scale(scale, scale);
    this.separationViolations.forEach((violation) => {
      CanvasUtils.drawLine(
        ctx,
        violation.aircrafts[0].position,
        violation.aircrafts[1].position,
        violation.status === SeparationNotificationStatus.Warn
          ? Colors.separationWarn
          : Colors.separationFail,
        5,
      );
    });
    ctx.restore();
  }
}
