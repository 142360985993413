export enum GameErrorLevel {
  FATAL,
  ERROR,
  WARN,
  INFO,
}

export class BaseGameError extends Error {
  constructor(protected level: GameErrorLevel, protected type: string, message?: string) {
    super(message ? `[${level}] ${type}: ${message}` : `[${level}] ${type}`);
  }
}
