import { Vector } from '../core/Vector';
import { TickableI } from '../interfaces/Tickable';
import { RadarScene } from '../scenes/RadarScene';
import { GameplayUtils } from '../utils/GameplayUtils';
import { GameUtils } from '../utils/GameUtils';
import { VectorUtils } from '../utils/VectorUtils';

const ARRIVAL_SPEEDS = [200, 205, 210, 215, 220, 225, 230, 235, 240];
const ARRIVAL_ALTS = [9500, 9000, 8500, 8000, 7500, 7000];

export class SpawnController implements TickableI {
  public lastSpawnAt: number = 0;

  constructor(private scene: RadarScene) {}

  public tick(n: number): void {
    if (n % 30 === 0 && Math.random() <= this.spawnChance && n - this.lastSpawnAt >= 60) {
      this.spawnAircraft();
      this.lastSpawnAt = n;
    }
  }

  private spawnAircraft(): void {
    if (Math.random() < 0.7) {
      if (this.scene.inboundAircrafts.length >= 10) {
        return;
      }

      const angle = Math.random() * Math.PI * 2;
      const spawnPoint: Vector = [
        Math.cos(angle) * this.scene.range,
        Math.sin(angle) * this.scene.range,
      ];

      const starOptions = this.scene.stars.map((star) => ({
        star,
        dist: VectorUtils.distance(spawnPoint, star.getEntryPosition()),
      }));

      const closestStar = starOptions.reduce((prev, curr) => {
        if (curr.dist < prev.dist) {
          return curr;
        }
        return prev;
      }, starOptions[0]);

      const bearing = VectorUtils.bearing(spawnPoint, closestStar.star.getEntryPosition());
      const speed = 220;
      const altitude = 7000;

      const ac = this.scene.addAircraft(
        GameplayUtils.generateCallsign(),
        spawnPoint,
        bearing,
        speed,
        altitude,
        { type: 'star', star: closestStar.star, currentFix: 0, currentDirect: false },
        { type: 'inbound' },
      );
      ac.setTargetAltitude(altitude);
      ac.setTargetSpeed(speed);
      // this.scene.radioController.fromAircraft(
      //   ac,
      //   `Heathrow Approach, ${ac.callsign} with you at ${altitude}`,
      // );
    } else {
      if (this.scene.outboundAircrafts.length >= 10) {
        return;
      }
      // const newAircraft = this.scene.addAircraft(
      //   GameplayUtils.generateCallsign(),
      //   GameUtils.randomElement(this.scene.runways.map((r) => r.getLandingPosition())),
      //   260,
      //   145,
      //   0,
      //   { type: 'outbound' },
      // );
      // newAircraft.setTargetAltitude(3000);
      // newAircraft.setTargetSpeed(180);
    }
  }

  private get spawnChance(): number {
    if (this.currentAircraftCount <= 1) {
      return 0.95;
    } else if (this.currentAircraftCount < 3) {
      return 0.65;
    } else if (this.currentAircraftCount < 6) {
      return 0.5;
    } else if (this.currentAircraftCount < 8) {
      return 0.3;
    } else if (this.currentAircraftCount < 10) {
      return 0.05;
    } else {
      return 0.01;
    }
  }

  private get currentAircraftCount(): number {
    return this.scene.aircrafts.length;
  }
}
