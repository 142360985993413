export const Colors = {
  background: '#0f2125',

  runway: '#3b9dff',
  runwayIls: '#888',
  runwayIlsHover: '#33ffec',

  aircraftInactive: '#FFFFFF',
  aircraftHover: '#75fffc',
  aircraftActive: '#ff77ff',

  commandDraft: '#ff8500',

  fix: '#297280',
  fixHover: '#70A2B0',

  mapFeatureOutline: '#28505a',
  mapFeatureDangerArea: '#500',
  mapFeatureRadarRing: '#183137',

  separationWarn: '#ffa500',
  separationFail: '#e30046',

  debugText: '#F00',

  uiPrimary: '#FFFFFF',
  uiSecondary: '#141414',
};

(window as any).Colors = Colors;
