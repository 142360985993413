import { Engine } from '../core/Engine';
import { Dictionary } from '../lib/Dictionary';

export enum KeyboardManagerKey {
  DebugToggle,
  ZoomOut,
  ZoomIn,
  SpeedDown,
  SpeedUp,

  Escape,
}

export interface ModifierKeysState {
  shift: boolean;
}

type KeypressHandler = (key: KeyboardManagerKey) => void;
type ModStateHandler = (state: ModifierKeysState) => void;

const basicKeyPressMap: Dictionary<KeyboardManagerKey> = {
  Minus: KeyboardManagerKey.ZoomOut,
  Equal: KeyboardManagerKey.ZoomIn,
  Backquote: KeyboardManagerKey.DebugToggle,
  BracketLeft: KeyboardManagerKey.SpeedDown,
  BracketRight: KeyboardManagerKey.SpeedUp,

  Escape: KeyboardManagerKey.Escape,
};

export class KeyboardManager {
  public modState: ModifierKeysState = { shift: false };

  constructor(engine: Engine, doc: Document, keyPressHandler: KeypressHandler) {
    doc.onkeyup = (e) => {
      if (inInput(e)) {
        return;
      }

      if (e.code === 'ShiftLeft') {
        this.modState.shift = false;
      }
    };

    doc.onkeydown = (e) => {
      // if (inInput(e)) {
      //   return;
      // }

      if (e.code === 'ShiftLeft') {
        this.modState.shift = true;
      }

      if (
        (e.ctrlKey === true || e.metaKey === true) &&
        (e.code === 'Minus' || e.code === 'Equal')
      ) {
        if (e.code === 'Minus') {
          keyPressHandler(KeyboardManagerKey.ZoomOut);
        } else if (e.code === 'Equal') {
          keyPressHandler(KeyboardManagerKey.ZoomIn);
        }
        e.preventDefault();
        return;
      }

      const mappedKey = basicKeyPressMap[e.code];
      if (mappedKey !== undefined) {
        keyPressHandler(mappedKey);
        e.preventDefault();
      } else if (engine.debugMode) {
        console.log(`Unknown key down: ${e.code}`);
      }
    };
  }
}

function inInput(e: any): boolean {
  return e?.target?.nodeName === 'INPUT';
}
