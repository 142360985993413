import { Vector } from '../core/Vector';

export class VectorUtils {
  public static floor(v: Vector): Vector {
    return [~~v[0], ~~v[1]];
  }

  public static fromDirectional(distance: number, heading: number): Vector {
    return [
      distance * Math.cos(((heading - 90) * Math.PI) / 180.0),
      distance * Math.sin(((heading - 90) * Math.PI) / 180.0),
    ];
  }

  public static add(v1: Vector, v2: Vector): Vector {
    return [v1[0] + v2[0], v1[1] + v2[1]];
  }

  public static subtract(v1: Vector, v2: Vector): Vector {
    return [v1[0] - v2[0], v1[1] - v2[1]];
  }

  public static scale(v1: Vector, factor: number): Vector {
    return [v1[0] * factor, v1[1] * factor];
  }

  public static distance(v1: Vector, v2: Vector): number {
    const [x1, y1] = v1;
    const [x2, y2] = v2;

    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }

  public static angle(v1: Vector, v2: Vector): number {
    const [x1, y1] = v1;
    const [x2, y2] = v2;

    const xD = x2 - x1;
    const yD = y2 - y1;

    const angle = (Math.atan(yD / xD) * 180) / Math.PI;
    if (xD < 0) {
      return angle + 180;
    }
    return angle;
  }

  public static bearing(v1: Vector, v2: Vector): number {
    return ((-(Math.atan2(v1[0] - v2[0], v1[1] - v2[1]) * (180 / Math.PI)) % 360) + 360) % 360;
  }

  public static distanceToSegment(p: Vector, v: Vector, w: Vector): number {
    return Math.sqrt(distToSegmentSquared(p, v, w));
  }

  public static lineIntersect(
    [x1, y1]: Vector,
    [x2, y2]: Vector,
    [x3, y3]: Vector,
    [x4, y4]: Vector,
  ): Vector | null {
    const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

    // Lines are parallel
    if (denominator === 0) {
      return null;
    }

    const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
      return null;
    }

    // Return a object with the x and y coordinates of the intersection
    return [x1 + ua * (x2 - x1), y1 + ua * (y2 - y1)];
  }
}

function sqr(x: number): number {
  return x * x;
}

function dist2(v: Vector, w: Vector): number {
  return sqr(v[0] - w[0]) + sqr(v[1] - w[1]);
}

function distToSegmentSquared(p: Vector, v: Vector, w: Vector): number {
  const l2 = dist2(v, w);
  if (l2 === 0) {
    return dist2(p, v);
  }
  let t = ((p[0] - v[0]) * (w[0] - v[0]) + (p[1] - v[1]) * (w[1] - v[1])) / l2;
  t = Math.max(0, Math.min(1, t));
  return dist2(p, [v[0] + t * (w[0] - v[0]), v[1] + t * (w[1] - v[1])]);
}
