import { RenderableI } from '../interfaces/Renderable';
import { FixableI } from '../interfaces/Fixable';
import { Colors } from '../render/Colors';
import { BaseEntity } from './BaseEntity';
import { EntityType } from './EntityType';
import { RadarScene } from '../scenes/RadarScene';
import { Vector } from '../core/Vector';

export interface StarDefinition {
  identifier: string;
  fixes: string[];
  labelOffset?: number;
  exitHeading: number;
}

export class StarEntity extends BaseEntity implements RenderableI {
  public exitHeading: number;
  private identifier: string;
  private labelOffset: number;
  private starPath: Vector[];

  constructor(public scene: RadarScene, def: StarDefinition, public fixes: FixableI[]) {
    super(EntityType.Star);
    this.labelOffset = def.labelOffset || 0;
    this.identifier = def.identifier;
    this.exitHeading = def.exitHeading;
    this.starPath = this.fixes.map((f) => f.getFixPosition()).reverse();
  }

  public render(ctx: CanvasRenderingContext2D, scale: number): void {
    ctx.save();
    ctx.scale(scale, scale);

    const endPos = this.starPath[0];
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.moveTo(...endPos);
    ctx.setLineDash([10, 10]);
    this.starPath.forEach((position) => {
      ctx.lineTo(...position);
    });
    ctx.moveTo(...endPos);
    ctx.closePath();
    ctx.strokeStyle = Colors.fix;
    ctx.stroke();

    ctx.translate(...this.getEntryPosition());
    ctx.font = '22px courier';
    ctx.textAlign = 'left';
    ctx.fillText(this.identifier, 35, 7 + -this.labelOffset * 22);

    ctx.restore();
  }

  public getEntryPosition(): Vector {
    return this.fixes[0].getFixPosition();
  }

  public getIdentifier(): string {
    return this.identifier;
  }
}
