import { Vector } from '../core/Vector';
import { Colors } from '../render/Colors';

export class CanvasUtils {
  public static elementIsCanvas(el: Element): el is HTMLCanvasElement {
    return el.nodeName === 'CANVAS';
  }

  public static drawLine(
    ctx: CanvasRenderingContext2D,
    from: Vector,
    to: Vector,
    strokeStyle: string,
    lineWidth: number,
  ): void {
    ctx.beginPath();
    ctx.lineWidth = lineWidth;
    ctx.moveTo(from[0], from[1]);
    ctx.lineTo(to[0], to[1]);
    ctx.strokeStyle = strokeStyle;
    ctx.closePath();
    ctx.stroke();
  }

  public static drawLineOfLength(
    ctx: CanvasRenderingContext2D,
    from: Vector,
    length: number,
    angle: number,
    strokeStyle: string,
    lineWidth: number,
  ): void {
    const rad = (angle * Math.PI) / 180.0;
    const [x1, y1] = from;
    ctx.beginPath();
    ctx.lineWidth = lineWidth;
    ctx.moveTo(x1, y1);
    ctx.lineTo(x1 + length * Math.cos(rad), y1 + length * Math.sin(rad));
    ctx.strokeStyle = strokeStyle;
    ctx.closePath();
    ctx.stroke();
  }

  public static drawCrosshair(
    ctx: CanvasRenderingContext2D,
    position: Vector,
    style: string,
  ): void {
    ctx.fillStyle = style;
    CanvasUtils.drawLine(
      ctx,
      [position[0], position[1] - 10],
      [position[0], position[1] + 10],
      style,
      2,
    );
    CanvasUtils.drawLine(
      ctx,
      [position[0] - 10, position[1]],
      [position[0] + 10, position[1]],
      style,
      2,
    );
    ctx.font = '15px courier';
    ctx.fillText(`${position[0]},${position[1]}`, ...position);
  }

  public static drawRoundedRect(
    ctx: CanvasRenderingContext2D,
    c1: Vector,
    c2: Vector,
    r: number,
    strokeStyle: string,
  ): void {
    const [x1, y1] = c1;
    const [x2, y2] = c2;
    const w = x2 - x1;
    const h = y2 - y1;
    if (r > w / 2) { r = w / 2; }
    if (r > h / 2) { r = h / 2; }
    ctx.beginPath();
    ctx.moveTo(x2 - r, y1);
    ctx.quadraticCurveTo(x2, y1, x2, y1 + r);
    ctx.lineTo(x2, y2 - r);
    ctx.quadraticCurveTo(x2, y2, x2 - r, y2);
    ctx.lineTo(x1 + r, y2);
    ctx.quadraticCurveTo(x1, y2, x1, y2 - r);
    ctx.lineTo(x1, y1 + r);
    ctx.quadraticCurveTo(x1, y1, x1 + r, y1);
    ctx.strokeStyle = strokeStyle;
    ctx.closePath();
    ctx.stroke();
  }

  public static drawRegularPolygon(
    ctx: CanvasRenderingContext2D,
    position: Vector,
    sides: number,
    size: number,
    style: string,
    lineWidth: number,
  ): void {
    ctx.beginPath();
    ctx.moveTo(position[0] + size * Math.cos(0), position[1] + size * Math.sin(0));

    for (let i = 1; i <= sides; i++) {
      ctx.lineTo(
        position[0] + size * Math.cos((i * 2 * Math.PI) / sides),
        position[1] + size * Math.sin((i * 2 * Math.PI) / sides),
      );
    }

    ctx.strokeStyle = style;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
  }
}
