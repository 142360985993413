import { RenderableI } from '../interfaces/Renderable';
import { CanvasUtils } from '../utils/CanvasUtils';
import { VectorUtils } from '../utils/VectorUtils';
import { Colors } from '../render/Colors';
import { AngleUtils } from '../utils/AngleUtils';
import { BaseEntity } from './BaseEntity';
import { EntityType } from './EntityType';
import { Vector } from '../core/Vector';
import { ClickableI } from '../interfaces/Clickable';
import { RadarScene } from '../scenes/RadarScene';

export interface RunwayDefinition {
  position: Vector;
  angle: number;
  length: number;
  identifier: string;
  ilsRange?: number;
}

export class RunwayEntity extends BaseEntity implements RenderableI, ClickableI {
  public position: Vector;
  public angle: number;
  public length: number;
  public identifier: string;
  private ilsRange: number;
  private ilsMinPosition: Vector;
  private ilsMaxPosition: Vector;
  private ilsMinorTicks: Vector[];

  constructor(public scene: RadarScene, def: RunwayDefinition) {
    super(EntityType.Runway);
    this.position = def.position;
    this.angle = def.angle;
    this.length = def.length;
    this.identifier = def.identifier;
    this.ilsRange = def.ilsRange || 600;

    this.ilsMinPosition = VectorUtils.add(
      this.position,
      VectorUtils.fromDirectional(length / 2 + 75, this.angle + 180),
    );
    this.ilsMaxPosition = VectorUtils.add(
      this.position,
      VectorUtils.fromDirectional(this.ilsRange, this.angle + 180),
    );

    this.ilsMinorTicks = [];
    for (let offset = length / 2 + 125; offset < this.ilsRange; offset += 50) {
      this.ilsMinorTicks.push(
        VectorUtils.add(this.position, VectorUtils.fromDirectional(offset, this.angle + 180)),
      );
    }
  }

  public inClickRegion(click: Vector): boolean {
    return VectorUtils.distanceToSegment(click, this.position, this.ilsMaxPosition) < 15;
  }

  public onClick(): boolean {
    this.scene.textCommandController.setLastToken(this.identifier);
    return true;
  }

  public render(ctx: CanvasRenderingContext2D, scale: number): void {
    ctx.save();
    ctx.scale(scale, scale);
    CanvasUtils.drawLineOfLength(
      ctx,
      this.position,
      this.length / 2,
      this.angle - 270,
      Colors.runway,
      6,
    );

    if (
      this.scene.mousePosition &&
      this.inClickRegion(this.scene.mousePosition) &&
      this.scene.clickableEntities().includes(this)
    ) {
      ctx.fillStyle = Colors.runwayIlsHover;
    } else {
      ctx.fillStyle = Colors.runwayIls;
    }

    CanvasUtils.drawLine(ctx, this.ilsMinPosition, this.ilsMaxPosition, ctx.fillStyle, 1);

    ctx.save();
    ctx.translate(this.ilsMaxPosition[0], this.ilsMaxPosition[1]);
    ctx.rotate(AngleUtils.toRad(this.angle - 90) + Math.PI / 2);
    ctx.fillRect(-11, -1, 22, 1);
    ctx.restore();

    this.ilsMinorTicks.forEach((tick, i) => {
      ctx.save();
      ctx.translate(tick[0], tick[1]);
      ctx.rotate(AngleUtils.toRad(this.angle - 90) + Math.PI / 2);
      if (i === (this.ilsMinorTicks.length - 1) / 2) {
        ctx.fillRect(-11, -1, 22, 1);
      } else {
        ctx.fillRect(-7, -1, 14, 1);
      }
      ctx.restore();
    });

    ctx.save();
    ctx.translate(...this.position);
    ctx.fillStyle = Colors.runway;
    ctx.font = '15px courier';
    ctx.textAlign = 'center';

    ctx.rotate((this.angle * Math.PI) / 180.0);
    ctx.fillText(this.identifier, 0, this.length / 2 + 17);

    ctx.restore();
    ctx.restore();
  }

  public debugRender(ctx: CanvasRenderingContext2D, scale: number): void {
    ctx.save();
    ctx.scale(scale, scale);
    CanvasUtils.drawLineOfLength(
      ctx,
      this.position,
      this.ilsRange,
      this.angle + 90 + 3,
      Colors.debugText,
      2,
    );
    CanvasUtils.drawLineOfLength(
      ctx,
      this.position,
      this.ilsRange,
      this.angle + 90 - 3,
      Colors.debugText,
      2,
    );
    ctx.restore();
  }

  public getLandingPosition(): Vector {
    return this.position;
  }

  public getIlsRange(): number {
    return this.ilsRange;
  }

  public getIlsMaxPosition(): Vector {
    return this.ilsMaxPosition;
  }
}
