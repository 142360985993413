import { Engine } from '../core/Engine';
import { Vector } from '../core/Vector';
import { VectorUtils } from '../utils/VectorUtils';

type ClickHandler = (p: Vector) => boolean;
type PanHandler = (v: Vector) => void;
type MousePosHandler = (p: Vector | null) => void;
type ZoomHandler = (d: number) => void;

export class MouseManager {
  private lastSentPanPos: Vector | null = null;

  constructor(
    private engine: Engine,
    private doc: Document,
    clickHandler: ClickHandler,
    mousePosHandler: MousePosHandler,
    panHandler: PanHandler,
  ) {
    doc.addEventListener('contextmenu', (event) => event.preventDefault());
    doc.onmousedown = (e) => {
      if (inInput(e)) {
        return;
      }

      if (e.metaKey) {
        this.lastSentPanPos = [e.x, e.y];
      }
    };

    doc.onwheel = (e) => {
      panHandler(VectorUtils.scale([e.deltaX, e.deltaY], -2));
    };

    doc.onmousemove = (e) => {
      if (this.lastSentPanPos) {
        const diff: Vector = [this.lastSentPanPos[0] - e.x, this.lastSentPanPos[1] - e.y];
        panHandler(VectorUtils.scale(diff, -2));
        this.lastSentPanPos = [e.x, e.y];
      } else {
        mousePosHandler([e.x * 2, e.y * 2]);
      }
    };

    doc.onmouseup = (e) => {
      if (inInput(e)) {
        return;
      }

      if (this.lastSentPanPos) {
        this.lastSentPanPos = null;
      } else {
        clickHandler([e.x * 2, e.y * 2]);
      }
    };

    doc.onmouseleave = (e) => {
      if (this.lastSentPanPos) {
        this.lastSentPanPos = null;
      } else {
        mousePosHandler(null);
      }
    };
  }
}

function inInput(e: any): boolean {
  return e?.target?.nodeName === 'INPUT';
}
