export class AngleUtils {
  public static clamp(angle: number): number {
    if (angle > 360) {
      return angle - 360;
    } else if (angle < 0) {
      return angle + 360;
    }
    return angle;
  }

  public static toRad(angle: number): number {
    return (angle * Math.PI) / 180.0;
  }

  public static diff(a: number, b: number): number {
    const phi = Math.abs(a - b) % 360;
    return phi > 180 ? 360 - phi : phi;
  }
}
