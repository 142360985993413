import { Vector } from '../core/Vector';
import { Colors } from '../render/Colors';
import { RadarScene } from '../scenes/RadarScene';
import { BasePanel } from './BasePanel';

export class InboundAircraftPanel extends BasePanel {
  constructor(private scene: RadarScene) {
    super('Inbound', [500, 400], scene.engine.screenSize);
  }

  protected positioner(screenSize: Vector): Vector {
    return [screenSize[0] - this.size[0], 0];
  }

  protected renderPanelContent(ctx: CanvasRenderingContext2D): void {
    ctx.fillStyle = Colors.uiPrimary;
    ctx.font = '26px courier';
    this.scene.inboundAircrafts.forEach((a, i) => {
      const isFocussed = this.scene.focussedAircraft === a;
      if (isFocussed) {
        ctx.fillStyle = Colors.aircraftActive;
      }
      ctx.fillText(`${a.callsign}`, 12, 63 + i * 35);
      ctx.textAlign = 'right';
      ctx.fillText(`${a.routingString}`, 488, 63 + i * 35);
      ctx.textAlign = 'left';
      if (isFocussed) {
        ctx.fillStyle = Colors.uiPrimary;
      }
    });
  }

  protected onInternalClick([x, y]: Vector): boolean {
    if (y < 35) {
      return false;
    }

    const index = Math.floor((y - 35) / 35);
    const aircraft = this.scene.inboundAircrafts[index];

    if (aircraft) {
      this.scene.focussedAircraft = aircraft;
      return true;
    }

    return false;
  }
}
