import { ParsedCommand } from './ParsedCommand';
import { RadarScene } from '../scenes/RadarScene';

export type CommandNodeType =
  | 'aircraft'
  | 'set-routing'
  | 'set-star'
  | 'set-localizer'
  | 'set-speed'
  | 'set-altitude'
  | 'set-hold'
  | 'cancel-hold'
  | 'direct-to'
  | 'assign-heading'
  | 'exit-heading'
  | null;

export class CommandParser {
  constructor(private scene: RadarScene) {}

  public parse(command: string): [ParsedCommand, CommandNodeType, string[], string] {
    const draftCommand: ParsedCommand = {
      aircraft: null,
      routing: undefined,
      assignedHeading: undefined,
      assignedLocalizer: undefined,
      setAltitude: undefined,
      setSpeed: undefined,
      holdAt: undefined,
      directTo: undefined,
      cancelHold: undefined,
    };
    const pastNodes: CommandNodeType[] = [];
    let openNode: CommandNodeType = 'aircraft';
    let hintSuffix = ' ';

    if (command.length === 0) {
      return [draftCommand, openNode, [], hintSuffix];
    }

    const tokens = command.split(' ');
    const aircraft = this.scene.findAircraftByCallsign(tokens[0]);
    if (aircraft) {
      draftCommand.aircraft = aircraft;
      openNode = null;
      pastNodes.push('aircraft');
    }

    let closeToken = false;
    for (const token of tokens) {
      if (closeToken) {
        pastNodes.push(openNode);
        openNode = null;
        closeToken = false;
      }

      if (!openNode) {
        switch (token) {
          case 'HEADING':
          case 'HDG':
            openNode = 'assign-heading';
            break;
          case 'ILS':
          case 'LOC':
            openNode = 'set-localizer';
            break;
          case 'STAR':
            openNode = 'set-star';
            break;
          case 'ROUTE':
          case 'RR':
            openNode = 'set-routing';
            break;
          case 'EXIT':
            if (
              pastNodes[pastNodes.length - 1] === 'set-routing' ||
              pastNodes[pastNodes.length - 1] === 'set-star'
            ) {
              openNode = 'exit-heading';
            }
            break;
          case 'SPEED':
          case 'SPD':
            openNode = 'set-speed';
            break;
          case 'ALTITUDE':
          case 'ALT':
            openNode = 'set-altitude';
            break;
          case 'HOLD':
            openNode = 'set-hold';
            break;
          case 'CANCELHOLD':
          case 'CONTINUE':
            openNode = 'cancel-hold';
            break;
          case 'DIRECT':
            openNode = 'direct-to';
            break;
          default:
            openNode = null;
        }
      } else {
        if (openNode === 'assign-heading') {
          const heading = Number(token);
          if (!isNaN(heading) && token.length > 0) {
            draftCommand.assignedHeading = heading;
            closeToken = true;
          }
        } else if (openNode === 'exit-heading' && draftCommand.routing) {
          const heading = Number(token);
          if (!isNaN(heading) && token.length > 0) {
            draftCommand.routing.exitHeading = heading;
            closeToken = true;
          }
        } else if (openNode === 'set-localizer') {
          const runway = this.scene.runwayMap[token];
          if (runway) {
            draftCommand.assignedLocalizer = runway;
            closeToken = true;
          }
        } else if (openNode === 'set-star') {
          const star = this.scene.starMap[token];
          if (star) {
            draftCommand.routing = {
              type: 'star',
              star,
              currentDirect: false,
              currentFix: 0,
            };
            closeToken = true;
          }
        } else if (openNode === 'set-routing') {
          const fixIds = token.split('.');
          const fixes = fixIds.map((id) => this.scene.fixableMap[id]);
          const validFixes = fixes.filter((s) => s);
          if (validFixes.length > 0) {
            draftCommand.routing = {
              type: 'custom',
              fixes: validFixes,
              currentDirect: false,
              currentFix: 0,
            };
            closeToken = true;
          }
        } else if (openNode === 'set-speed') {
          const speed = Number(token);
          if (!isNaN(speed)) {
            draftCommand.setSpeed = speed;
            closeToken = true;
          }
        } else if (openNode === 'set-altitude') {
          const altitude = Number(token);
          if (!isNaN(altitude)) {
            draftCommand.setAltitude = altitude * 100;
            closeToken = true;
          }
        } else if (openNode === 'set-hold') {
          const fix = this.scene.fixableMap[token];
          if (fix) {
            draftCommand.holdAt = fix;
            closeToken = true;
          }
        } else if (openNode === 'cancel-hold') {
          draftCommand.cancelHold = true;
          closeToken = true;
        } else if (openNode === 'direct-to') {
          const fix = this.scene.fixableMap[token];
          if (fix) {
            draftCommand.directTo = fix;
            closeToken = true;
          }
        }
      }
    }

    const lastToken = tokens[tokens.length - 1];
    let hints: string[] = [];

    if (openNode === 'aircraft' && tokens.length === 1 && !draftCommand.aircraft) {
      hints = this.generateHint(
        lastToken,
        this.scene.aircrafts.map((a) => a.callsign),
      );
    } else if (openNode === null && lastToken.length > 0) {
      hints = this.generateHint(lastToken, [
        ...(pastNodes[pastNodes.length - 1] === 'set-routing' ||
        pastNodes[pastNodes.length - 1] === 'set-star'
          ? ['EXIT']
          : []),
        ...(!draftCommand.assignedHeading && !draftCommand.routing ? ['HEADING', 'HDG'] : []),
        ...(!draftCommand.assignedLocalizer ? ['ILS'] : []),
        ...(!draftCommand.routing && !draftCommand.assignedHeading ? ['STAR', 'ROUTE'] : []),
        ...(!draftCommand.holdAt ? ['HOLD'] : []),
        ...(!draftCommand.setSpeed ? ['SPEED'] : []),
        ...(!draftCommand.setAltitude ? ['ALTITUDE'] : []),
        ...(!draftCommand.cancelHold &&
        !draftCommand.routing &&
        draftCommand.aircraft?.holdInstruction
          ? ['CANCELHOLD']
          : []),
        ...(!draftCommand.cancelHold && !draftCommand.routing && draftCommand.aircraft?.activeHold
          ? ['CONTINUE']
          : []),
        ...(!draftCommand.directTo && !draftCommand.routing ? ['DIRECT'] : []),
      ]);
    } else if (openNode === 'set-routing') {
      const fixes = lastToken.split('.');
      const lastFix = fixes[fixes.length - 1];
      const filteredFixes = Object.keys(this.scene.fixableMap).filter((id) => !fixes.includes(id));
      hints = this.generateHint(lastFix, filteredFixes);
      hintSuffix = '';
    } else if (openNode === 'set-hold' && draftCommand.aircraft) {
      const rI = draftCommand.routing || draftCommand.aircraft.routingInstruction;
      const fixes = (rI.type === 'star' ? rI.star.fixes : rI.fixes).slice(rI.currentFix);
      const ids = fixes.map((f) => f.getFixIdentifier());
      hints = this.generateHint(lastToken, ids);
    } else if (openNode === 'direct-to' && draftCommand.aircraft) {
      const rI = draftCommand.aircraft.routingInstruction;
      const fixes = (rI.type === 'star' ? rI.star.fixes : rI.fixes).slice(rI.currentFix + 1);
      const ids = fixes.map((f) => f.getFixIdentifier());
      hints = this.generateHint(lastToken, ids);
    } else if (openNode === 'set-star') {
      hints = this.generateHint(lastToken, Object.keys(this.scene.starMap));
    } else if (openNode === 'set-localizer') {
      hints = this.generateHint(lastToken, Object.keys(this.scene.runwayMap));
    }

    return [draftCommand, openNode, hints, hintSuffix];
  }

  public generateHint(token: string, potential: string[]): string[] {
    const matches = potential.filter((p) => p.slice(0, token.length) === token);
    if (!matches) {
      return [];
    }
    return matches.map((m) => m.slice(token.length));
  }
}
