import { Vector } from '../core/Vector';
import { UIRenderableI } from '../interfaces/UIRenderable';
import { Colors } from '../render/Colors';
import { RadarScene } from '../scenes/RadarScene';

export class ScoreController implements UIRenderableI {
  public aircraftLanded: number = 0;
  public aircraftDeparted: number = 0;
  public seperationViolations: number = 0;
  public missedApproaches: number = 0;

  constructor(private scene: RadarScene) {}

  public render(ctx: CanvasRenderingContext2D, screenSize: Vector): void {
    ctx.save();
    ctx.translate(screenSize[0] / 2, 40);
    ctx.fillStyle = Colors.uiPrimary;
    ctx.font = '32px courier';
    ctx.textAlign = 'center';
    ctx.fillText(
      `${this.aircraftLanded} Landed, ${this.aircraftDeparted} Departed, ${this.seperationViolations} Violations, ${this.missedApproaches} Missed Approaches`,
      0,
      0,
    );
    ctx.restore();
  }
}
