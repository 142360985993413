import { BaseGameError, GameErrorLevel } from './BaseGameError';

export class CanvasNotFoundError extends BaseGameError {
  constructor(selector: string) {
    super(GameErrorLevel.FATAL, 'CanvasNotFound', `${selector} not found`);
  }
}

export class ElementNotCanvasError extends BaseGameError {
  constructor() {
    super(GameErrorLevel.FATAL, 'ElementNotCanvasError');
  }
}
