import { CanvasUtils } from '../utils/CanvasUtils';
import { RenderableI } from '../interfaces/Renderable';
import { Vector } from '../core/Vector';
import { VectorUtils } from '../utils/VectorUtils';
import { Colors } from './Colors';

export class GameRenderer {
  public viewOffset: Vector = [0, 0];
  public scale: number = 1;
  public zoomLevel: number = 0;
  private context: CanvasRenderingContext2D;

  constructor(public screenSize: Vector, public canvas: HTMLCanvasElement) {
    this.context = canvas.getContext('2d', { alpha: false })!;
    this.setupCanvas();
  }

  public async init(): Promise<void> {
    this.clearCanvas();
  }

  public handleResize(screenSize: Vector): void {
    this.screenSize = screenSize;
    this.setupCanvas();
  }

  public renderFrame(
    renderables: RenderableI[],
    engineDebugRender: (ctx: CanvasRenderingContext2D) => void,
    debugMode: boolean,
  ): void {
    this.clearCanvas();
    renderables.forEach((r) => {
      r.render(this.context, this.scale, this.screenSize);
      if (debugMode && r.debugRender) {
        r.debugRender(this.context, this.scale, this.screenSize);
      }
    });

    if (debugMode) {
      this.context.save();
      this.context.translate(-this.viewOffset[0], -this.viewOffset[1]);
      engineDebugRender(this.context);
      this.context.restore();

      this.context.fillStyle = Colors.debugText;
      CanvasUtils.drawLine(this.context, [0, -10], [0, 10], Colors.debugText, 2);
      CanvasUtils.drawLine(this.context, [-10, 0], [10, 0], Colors.debugText, 2);
      this.context.font = '15px courier';
      this.context.fillText('0,0', 8, 18);
    }
  }

  public panView(diff: Vector): void {
    this.viewOffset = VectorUtils.add(this.viewOffset, diff);
    this.context.translate(diff[0], diff[1]);
  }

  public zoomIn(): void {
    if (this.zoomLevel > 5) {
      return;
    }
    this.zoomLevel++;
    const mod = 1.25;
    this.scale *= mod;
    console.log(`Scaling Factor: ${this.zoomLevel}`);
  }

  public zoomOut(): void {
    if (this.zoomLevel < -4) {
      return;
    }
    this.zoomLevel--;
    const mod = 1.25;
    this.scale /= mod;
    console.log(`Scaling Factor: ${this.zoomLevel}`);
  }

  private setupCanvas(): void {
    this.canvas.width = this.screenSize[0];
    this.canvas.height = this.screenSize[1];
    this.viewOffset = [this.screenSize[0] / 2 - 200, this.screenSize[1] / 2];
    this.context.translate(...this.viewOffset);

    this.clearCanvas();
    console.log(`Game canvas calibration complete. ${this.screenSize[0]}x${this.screenSize[1]}`);
  }

  private clearCanvas(): void {
    const { width, height } = this.context.canvas;
    this.context.fillStyle = Colors.background;
    this.context.fillRect(-this.viewOffset[0], -this.viewOffset[1], width, height);
  }
}
