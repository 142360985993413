import '@babel/polyfill';
import './scss/index.scss';
import { Engine } from './core/Engine';
import EGLL from './maps/egll';

const engine = new Engine('#gameplay', '#uilayer');
engine.start();

const radarScene = engine.transitionToRadar(EGLL);

const aircraft1 = radarScene.addAircraft(
  'JAM1',
  [2580, -1175],
  180,
  180,
  3000,
  {
    type: 'star',
    star: radarScene.starMap.LAM1A,
    currentFix: 0,
    currentDirect: false,
  },
  {
    type: 'inbound',
  },
);
// aircraft1.activeLocalizer = runway26R;
// aircraft1.holdInstruction = radarScene.fixableMap.LAM;
// aircraft1.targetSpeed = 220;

const aircraft2 = radarScene.addAircraft(
  'JAM2',
  [715, 407],
  315,
  220,
  3000,
  {
    type: 'star',
    star: radarScene.starMap.BIG2B,
    currentFix: 2,
    currentDirect: false,
    exitHeading: 335,
  },
  {
    type: 'inbound',
  },
);
aircraft2.setLocalizer(radarScene.runwayMap['26L']);
