import { RenderableI } from '../interfaces/Renderable';
import { TickableI } from '../interfaces/Tickable';
import { CanvasUtils } from '../utils/CanvasUtils';
import { VectorUtils } from '../utils/VectorUtils';
import { AngleUtils } from '../utils/AngleUtils';
import { PositionInstruction } from '../interfaces/PositionInstruction';
import { RoutingInstruction } from '../interfaces/RoutingInstruction';
import { Colors } from '../render/Colors';
import { FormattingUtils } from '../utils/FormattingUtils';
import { ClickableI } from '../interfaces/Clickable';
import { RadarScene } from '../scenes/RadarScene';
import { EntityType } from './EntityType';
import { BaseEntity } from './BaseEntity';
import { RunwayEntity } from './RunwayEntity';
import { Vector } from '../core/Vector';
import { SpatialUtils } from '../utils/SpacialUtils';
import { AircraftGoal } from '../interfaces/AircraftGoal';
import { FixableI } from '../interfaces/Fixable';
import { isMissing, notMissing } from '../lib/typeGuards';
import { CONNREFUSED } from 'dns';

let currentIdGenerator = 0;

interface ActiveHold {
  fix: FixableI;
  heading: number;
  cancelled: boolean;
}

export class AircraftEntity extends BaseEntity implements RenderableI, TickableI, ClickableI {
  public entityType = EntityType.Aircraft;
  public uuid = ++currentIdGenerator;

  public targetSpeed: number;
  public targetAltitude: number;
  public targetHeading: number;
  public onFinal: boolean = false;
  public holdInstruction: FixableI | null = null;
  public activeHold: ActiveHold | null = null;
  public activeLocalizer: RunwayEntity | null = null;
  public activeGlideslope: RunwayEntity | null = null;
  public assignedHeading: number | null = null;
  public emergency: boolean = false;

  private HOLDING_LEG = 180;
  private SPEED_TICK_RATIO = 60;
  private CLICK_RANGE = 70;
  private MAX_ALT_CHANGE_RATE = 50;
  private LANDING_SPEED = 140;

  private trail: Vector[] = [];

  constructor(
    public scene: RadarScene,
    public callsign: string,
    public position: Vector,
    public heading: number,
    public speed: number,
    public altitude: number,
    public routingInstruction: RoutingInstruction,
    public goal: AircraftGoal,
  ) {
    super(EntityType.Aircraft);

    this.targetSpeed = speed;
    this.targetAltitude = altitude;
    this.targetHeading = heading;
  }

  private get clickRegionCentre(): Vector {
    return VectorUtils.add(this.position, [30, -30]);
  }

  public inClickRegion(click: Vector): boolean {
    const [x, y] = this.clickRegionCentre;

    return Math.sqrt((click[0] - x) ** 2 + (click[1] - y) ** 2) < this.CLICK_RANGE;
  }

  public onClick(): boolean {
    this.scene.focussedAircraft = this;
    (window as any).Aircraft = this;
    return true;
  }

  public render(ctx: CanvasRenderingContext2D, scale: number): void {
    const isFocussed = this.scene.focussedAircraft === this;
    const [xC, yC] = this.position;
    ctx.lineWidth = 2;

    if (isFocussed) {
      ctx.strokeStyle = Colors.aircraftActive;
      ctx.fillStyle = Colors.aircraftActive;
    } else if (
      this.scene.mousePosition &&
      this.inClickRegion(this.scene.mousePosition) &&
      this.scene.clickableEntities().includes(this)
    ) {
      ctx.strokeStyle = Colors.aircraftHover;
      ctx.fillStyle = Colors.aircraftHover;
    } else {
      ctx.strokeStyle = Colors.aircraftInactive;
      ctx.fillStyle = Colors.aircraftInactive;
    }

    ctx.save();
    ctx.scale(scale, scale);
    ctx.translate(xC, yC);
    ctx.lineWidth = 5;
    ctx.strokeRect(-6, -6, 12, 12);

    ctx.font = '22px courier';
    ctx.textAlign = 'left';
    if (this.altitude < 2000) {
      ctx.fillText(`${FormattingUtils.twoDigitAltitude(this.altitude)}`, -9, -38);
      ctx.fillText(`${Math.round(this.speed)}`, -9, -18);
    } else {
      const altDeltaSymbol =
        this.altitude === this.targetAltitude
          ? '='
          : this.altitude > this.targetAltitude
          ? '▼'
          : '▲';
      const targetAlt = this.activeGlideslope
        ? 'GS'
        : `${FormattingUtils.twoDigitAltitude(this.targetAltitude)}`;
      const line1 = `${FormattingUtils.twoDigitAltitude(
        this.altitude,
      )}${altDeltaSymbol} ${targetAlt}`;
      const speedDeltaSymbol =
        this.speed === this.targetSpeed ? '' : this.speed > this.targetSpeed ? '▼' : '▲';
      const line2 = `${Math.round(this.speed)}${speedDeltaSymbol}`;
      ctx.fillText(this.callsign, -9, -58);
      ctx.fillText(line1, -9, -38);
      ctx.fillText(line2, -9, -18);
      if (this.emergency) {
        const previousFillStyle = ctx.fillStyle;
        ctx.fillStyle = 'red';
        ctx.fillText('EM', 42, -18);
        ctx.fillStyle = previousFillStyle;
      }
    }

    CanvasUtils.drawLineOfLength(
      ctx,
      [0, 0],
      this.movementPerTick * 20,
      this.heading - 90,
      ctx.strokeStyle,
      2,
    );

    ctx.restore();

    ctx.save();
    ctx.scale(scale, scale);
    const visibleTrail = this.trail.slice(-1 * (isFocussed ? this.trail.length : 6));
    for (let i = 0; i < visibleTrail.length - 1; i++) {
      const t = visibleTrail[i];
      ctx.fillRect(t[0] - 2, t[1] - 2, 4, 4);
    }

    if (isFocussed) {
      const draftCommand = this.scene.textCommandController.draftCommand;
      let currPos = this.position;
      let currHeading = this.targetHeading;
      if (this.activeGlideslope) {
        CanvasUtils.drawLine(
          ctx,
          currPos,
          this.activeGlideslope.getLandingPosition(),
          Colors.aircraftActive,
          1,
        );
      } else if (draftCommand.assignedHeading !== undefined) {
        const movementVector = VectorUtils.fromDirectional(100, draftCommand.assignedHeading);
        const displayedBug = VectorUtils.add(currPos, movementVector);
        CanvasUtils.drawLine(ctx, currPos, displayedBug, Colors.commandDraft, 1);
        currHeading = draftCommand.assignedHeading;
      } else if (isMissing(this.assignedHeading) || draftCommand.routing) {
        let routingInstruction = this.routingInstruction;
        let lineColor = Colors.aircraftActive;
        if (draftCommand.routing) {
          routingInstruction = draftCommand.routing;
          lineColor = Colors.commandDraft;
        }

        const fixes =
          routingInstruction.type === 'star'
            ? routingInstruction.star.fixes
            : routingInstruction.fixes;

        let currentFix = routingInstruction.currentFix;
        let isDirect = routingInstruction.currentDirect;
        if (draftCommand.directTo) {
          currentFix = fixes.indexOf(draftCommand.directTo);
          isDirect = true;
          lineColor = Colors.commandDraft;
        }

        if (currentFix === 0 || isDirect) {
          const firstPos = fixes[currentFix].getFixPosition();
          CanvasUtils.drawLine(ctx, currPos, firstPos, lineColor, 1);
          currHeading = VectorUtils.bearing(currPos, firstPos);
          currPos = firstPos;
        }

        fixes.slice(currentFix).forEach((fix) => {
          const nextPos = fix.getFixPosition();
          CanvasUtils.drawLine(ctx, currPos, nextPos, lineColor, 1);

          if (
            this.holdInstruction === fix ||
            (this.activeHold && this.activeHold.fix === fix) ||
            draftCommand.holdAt === fix
          ) {
            if (this.activeHold && this.activeHold.fix === fix) {
              currHeading = this.activeHold.heading;
            } else {
              currHeading = VectorUtils.bearing(currPos, nextPos);
            }

            let holdColor = lineColor;
            if (this.holdInstruction !== fix && (!this.activeHold || this.activeHold.fix !== fix)) {
              holdColor = Colors.commandDraft;
            }

            ctx.save();
            ctx.translate(...nextPos);
            ctx.rotate(AngleUtils.toRad(currHeading));
            const yOffset = this.movementPerTick / (2 * Math.sin(AngleUtils.toRad(1.5))) - 2;
            const xOffset = yOffset * 2 + 2;
            CanvasUtils.drawRoundedRect(
              ctx,
              [0, -yOffset + this.movementPerTick * 2],
              [xOffset, 250 - (yOffset + this.movementPerTick * 1.25)],
              100,
              holdColor,
            );
            ctx.restore();
          }

          currHeading = VectorUtils.bearing(currPos, nextPos);
          currPos = nextPos;
        });

        if (notMissing(routingInstruction.exitHeading)) {
          currHeading = routingInstruction.exitHeading;
        } else if (routingInstruction.type === 'star') {
          currHeading = routingInstruction.star.exitHeading;
        }

        if (notMissing(routingInstruction.exitHeading) || routingInstruction.type === 'star') {
          const movementVector = VectorUtils.fromDirectional(100, currHeading);
          const displayedBug = VectorUtils.add(currPos, movementVector);
          CanvasUtils.drawLine(ctx, currPos, displayedBug, lineColor, 1);
        }
      }

      let ilsLoc: RunwayEntity | null = null;
      let ilsColor = Colors.aircraftActive;
      if (draftCommand.assignedLocalizer) {
        ilsLoc = draftCommand.assignedLocalizer;
        ilsColor = Colors.commandDraft;
      } else if (this.activeLocalizer) {
        ilsLoc = this.activeLocalizer;
      }

      if (ilsLoc) {
        const movementVector = VectorUtils.fromDirectional(3000, currHeading);
        const maxFuturePos = VectorUtils.add(currPos, movementVector);
        const vec = VectorUtils.lineIntersect(
          currPos,
          maxFuturePos,
          ilsLoc.getLandingPosition(),
          ilsLoc.getIlsMaxPosition(),
        );
        if (vec) {
          CanvasUtils.drawLine(
            ctx,
            currPos,
            vec,
            draftCommand.assignedHeading ? Colors.commandDraft : ilsColor,
            1,
          );
          CanvasUtils.drawLine(ctx, vec, ilsLoc.getLandingPosition(), ilsColor, 1);
        }
      }
    }

    ctx.restore();
  }

  public debugRender(ctx: CanvasRenderingContext2D, scale: number): void {
    const [xC, yC] = this.clickRegionCentre;

    ctx.save();
    ctx.scale(scale, scale);
    ctx.translate(xC, yC);

    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = Colors.debugText;
    ctx.ellipse(0, 0, this.CLICK_RANGE, this.CLICK_RANGE, 0, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();

    ctx.restore();
  }

  public tick(n: number): void {
    // Next instruction progression checks
    if (this.activeLocalizer) {
      const runway = this.activeLocalizer;
      if (this.inIlsCone(runway)) {
        this.scene.radioController.fromAircraft(
          this,
          `${this.callsign} established ${runway.identifier}`,
        );
        this.activeLocalizer = null;
        this.activeGlideslope = runway;
        this.assignedHeading = null;
      }
    } else if (this.activeGlideslope) {
      const runway = this.activeGlideslope;
      const distance = this.distanceToPosition(runway.getLandingPosition());

      if (distance < 5) {
        if (this.altitude > 200 || this.speed > 140) {
          this.scene.scoreController.missedApproaches++;
          this.targetAltitude = 3000;
          this.targetSpeed = 180;
          this.assignedHeading = runway.angle;
          this.activeGlideslope = null;
        } else {
          this.scene.scoreController.aircraftLanded++;
          this.scene.removeAircraft(this);
          return;
        }
      }
    }

    if (!this.activeGlideslope && this.assignedHeading === null) {
      const fixes =
        this.routingInstruction.type === 'star'
          ? this.routingInstruction.star.fixes
          : this.routingInstruction.fixes;
      const currentFix = fixes[this.routingInstruction.currentFix];
      if (this.holdInstruction === currentFix) {
        const dist = this.distanceToPosition(this.holdInstruction.getFixPosition());
        if (dist <= this.HOLDING_LEG) {
          this.activeHold = {
            fix: this.holdInstruction,
            cancelled: false,
            heading: this.heading,
          };
          this.holdInstruction = null;
        }
      } else if (!this.activeHold) {
        const dist = this.distanceToPosition(currentFix.getFixPosition());
        if (dist < this.movementPerTick * 2) {
          const nextFix = fixes[this.routingInstruction.currentFix + 1];
          if (nextFix) {
            this.routingInstruction.currentFix++;
          } else {
            if (notMissing(this.routingInstruction.exitHeading)) {
              this.assignedHeading = this.routingInstruction.exitHeading;
            } else if (this.routingInstruction.type === 'star') {
              this.assignedHeading = this.routingInstruction.star.exitHeading;
            } else {
              this.assignedHeading = this.targetHeading;
            }
          }
        }
      }
    }

    // Active instruction processing
    if (this.activeGlideslope) {
      const runway = this.activeGlideslope;
      const distance = this.distanceToPosition(runway.getLandingPosition());
      const shortFinal = runway.length / 2 + 50;

      const glideslopeAltitude = Math.floor(distance / this.movementPerTick) * 40;
      if (this.altitude > glideslopeAltitude) {
        this.targetAltitude = glideslopeAltitude;
      }

      if (this.targetSpeed > this.LANDING_SPEED) {
        const slowingTicks = this.speed - this.LANDING_SPEED;
        const avgSpeed = (this.speed + this.LANDING_SPEED) / 2;
        const slowingDistance = slowingTicks * (avgSpeed / this.SPEED_TICK_RATIO);
        if (distance <= shortFinal + slowingDistance) {
          this.targetSpeed = this.LANDING_SPEED;
          this.onFinal = true;
        }
      } else if (distance <= shortFinal && !this.onFinal) {
        this.onFinal = true;
      }

      const runwayCenter = runway.getLandingPosition();
      const ilsMax = runway.getIlsMaxPosition();
      const distanceFromCenter = VectorUtils.distanceToSegment(
        this.position,
        runwayCenter,
        runway.getIlsMaxPosition(),
      );
      const [x1, y1] = runwayCenter;
      const [x2, y2] = ilsMax;
      const d = (this.position[0] - x1) * (y2 - y1) - (this.position[1] - y1) * (x2 - x1);
      const maxDist = distance * Math.sin(AngleUtils.toRad(3));
      const interceptAngle = Math.min(45, (45 * distanceFromCenter) / maxDist);
      if (d < 0) {
        this.targetHeading = runway.angle + interceptAngle;
      } else if (d > 0) {
        this.targetHeading = runway.angle - interceptAngle;
      }
    } else if (this.assignedHeading) {
      if (this.targetHeading !== this.assignedHeading) {
        this.targetHeading = this.assignedHeading;
      }
    } else if (this.activeHold) {
      const distance = VectorUtils.distance(this.position, this.activeHold.fix.getFixPosition());
      if (AngleUtils.diff(this.activeHold.heading, this.targetHeading) < 1) {
        if (distance <= this.movementPerTick * 2) {
          if (this.activeHold.cancelled) {
            this.activeHold = null;
          } else {
            this.heading += 1;
            this.targetHeading = AngleUtils.clamp(this.targetHeading + 180);
          }
        }
      } else {
        if (distance >= this.HOLDING_LEG) {
          this.heading += 1;
          this.targetHeading = this.activeHold.heading;
        }
      }
    } else {
      const fixes =
        this.routingInstruction.type === 'star'
          ? this.routingInstruction.star.fixes
          : this.routingInstruction.fixes;
      const fixIndex = this.routingInstruction.currentFix;
      const currentFix = fixes[fixIndex];
      if (fixIndex === 0 || this.routingInstruction.currentDirect) {
        this.targetHeading = AngleUtils.clamp(
          VectorUtils.angle(this.position, currentFix.getFixPosition()) + 90,
        );
      } else {
        const previousPoint = fixes[fixIndex - 1].getFixPosition();
        const nextPoint = currentFix.getFixPosition();
        const legBearing = VectorUtils.bearing(previousPoint, nextPoint);

        const distanceFromCenter = VectorUtils.distanceToSegment(
          this.position,
          nextPoint,
          previousPoint,
        );
        const [x1, y1] = nextPoint;
        const [x2, y2] = previousPoint;
        const d = (this.position[0] - x1) * (y2 - y1) - (this.position[1] - y1) * (x2 - x1);
        const distance = this.distanceToPosition(nextPoint);
        const maxDist = distance * Math.sin(AngleUtils.toRad(3));
        const interceptAngle = Math.min(45, (45 * distanceFromCenter) / maxDist);
        if (d < 0) {
          this.targetHeading = legBearing + interceptAngle;
        } else if (d > 0) {
          this.targetHeading = legBearing - interceptAngle;
        }
      }
    }

    if (this.targetSpeed > this.speed) {
      this.speed++;
    } else if (this.targetSpeed < this.speed) {
      this.speed--;
    }

    if (this.targetAltitude > this.altitude) {
      this.altitude = Math.min(this.targetAltitude, this.altitude + this.MAX_ALT_CHANGE_RATE);
    } else if (this.targetAltitude < this.altitude) {
      this.altitude = Math.max(this.targetAltitude, this.altitude - this.MAX_ALT_CHANGE_RATE);
    }

    if (this.targetHeading !== this.heading) {
      const diff = Math.abs(this.heading - this.targetHeading);
      if (diff > 0.1) {
        if (
          (this.heading - this.targetHeading >= 0 && this.heading - this.targetHeading <= 180) ||
          (this.heading - this.targetHeading <= -180 && this.heading - this.targetHeading >= -360)
        ) {
          this.heading -= Math.min(
            this.maxBankRate,
            AngleUtils.diff(this.targetHeading, this.heading),
          );
        } else {
          this.heading += Math.min(
            this.maxBankRate,
            AngleUtils.diff(this.targetHeading, this.heading),
          );
        }
        this.heading = AngleUtils.clamp(this.heading);
      }
    }

    const movementVector = VectorUtils.fromDirectional(this.movementPerTick, this.heading);
    this.position = VectorUtils.add(this.position, movementVector);

    if (n % 5 === 0) {
      this.trail.push(this.position);
    }

    if (VectorUtils.distance(this.position, [0, 0]) > this.scene.range) {
      this.scene.scoreController.aircraftDeparted++;
      this.scene.removeAircraft(this);
    }
  }

  public setTargetAltitude(alt: number): void {
    this.targetAltitude = alt;
  }

  public setTargetSpeed(speed: number): void {
    this.targetSpeed = speed;
  }

  public setLocalizer(runway: RunwayEntity | null): void {
    this.activeLocalizer = runway;
  }

  public assignHeading(heading: number): void {
    this.assignedHeading = heading;
    this.holdInstruction = null;
    this.activeHold = null;
  }

  public setRouting(instruction: RoutingInstruction): void {
    this.routingInstruction = instruction;
    this.assignedHeading = null;
    this.holdInstruction = null;
    this.activeHold = null;
  }

  public directTo(fix: FixableI): void {
    const fixes =
      this.routingInstruction.type === 'star'
        ? this.routingInstruction.star.fixes
        : this.routingInstruction.fixes;
    const index = fixes.indexOf(fix);
    if (index > -1) {
      this.routingInstruction.currentFix = index;
      this.routingInstruction.currentDirect = true;
      this.holdInstruction = null;
      this.assignedHeading = null;
      this.activeHold = null;
    }
  }

  public holdAt(fix: FixableI): void {
    if (this.activeHold) {
      this.activeHold.cancelled = true;
    }
    this.holdInstruction = fix;
  }

  public cancelHold(): void {
    this.holdInstruction = null;
    if (this.activeHold) {
      this.activeHold.cancelled = true;
    }
  }

  private distanceToPosition(position: Vector): number {
    return VectorUtils.distance(this.position, position);
  }

  private inIlsCone(runway: RunwayEntity): boolean {
    const landingPos = runway.getLandingPosition();
    const distance = VectorUtils.distance(this.position, landingPos);
    if (distance <= runway.getIlsRange()) {
      const targetBearing = runway.angle;
      const currentBearing = VectorUtils.angle(this.position, landingPos) + 90;
      return AngleUtils.diff(targetBearing, currentBearing) <= 3;
    }
    return false;
  }

  private get movementPerTick(): number {
    return this.speed / this.SPEED_TICK_RATIO;
  }

  private get maxBankRate(): number {
    if (this.activeGlideslope) {
      return 5;
    } else if (this.activeHold) {
      return 3;
    }

    return 8;
  }

  public get routingString(): string {
    if (this.routingInstruction.type === 'star') {
      return this.routingInstruction.star.getIdentifier();
    }

    return this.routingInstruction.fixes.map((f) => f.getFixIdentifier()).join(' ');
  }
}
