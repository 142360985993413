import { RadarOverlayUIBridge } from '../bridges/RadarOverlayUIBridge';
import { Vector } from '../core/Vector';
import { AircraftEntity } from '../entities/AircraftEntity';
import { UIRenderableI } from '../interfaces/UIRenderable';
import { Colors } from '../render/Colors';
import { RadarScene } from '../scenes/RadarScene';

interface RadioMessage {
  type: 'radio';
  aircraft: AircraftEntity;
  message: string;
  direction: 'outbound' | 'inbound';
  createdAt: number;
}

interface SystemMessage {
  type: 'system';
  message: string;
  createdAt: number;
}

type Message = RadioMessage | SystemMessage;

export class RadioController implements UIRenderableI {
  public messages: Message[] = [];

  constructor(private scene: RadarScene) {}

  public render(ctx: CanvasRenderingContext2D, screenSize: Vector): void {
    const currentTime = this.scene.engine.lastLoopTime || 0;

    ctx.save();
    ctx.translate(55, screenSize[1] - 100);
    ctx.font = '100 28px courier';
    ctx.textAlign = 'left';
    for (let i = this.messages.length - 1; i >= 0; i--) {
      const message = this.messages[i];
      ctx.fillStyle = message.type === 'radio' ? '#fff' : '#f00';
      const tos = currentTime - message.createdAt;
      if (tos > 3500) {
        const opacity = Math.min(Math.max(1 - (tos - 3500) / 500, 0), 1);
        ctx.fillStyle =
          message.type === 'radio'
            ? `rgba(255, 255, 255, ${opacity.toFixed(1)})`
            : `rgba(255, 0, 0, ${opacity.toFixed(1)})`;
      }
      ctx.fillText(message.message, 0, -35 * (this.messages.length - i - 1));
    }
    ctx.restore();
  }

  public fromAircraft(aircraft: AircraftEntity, message: string): void {
    this.messages.push({
      type: 'radio',
      aircraft,
      message,
      direction: 'inbound',
      createdAt: this.scene.engine.lastLoopTime || 0,
    });

    this.afterSend();
  }

  public toAircraft(aircraft: AircraftEntity, message: string): void {
    this.messages.push({
      type: 'radio',
      aircraft,
      message,
      direction: 'outbound',
      createdAt: this.scene.engine.lastLoopTime || 0,
    });

    this.afterSend();
  }

  public toController(message: string): void {
    this.messages.push({
      type: 'system',
      message,
      createdAt: this.scene.engine.lastLoopTime || 0,
    });

    this.afterSend();
  }

  private afterSend(): void {
    this.cleanupOldMessages();
    this.scene.engine.queueUIRender();
    this.scene.engine.uiAnimationTime(4000);
  }

  private cleanupOldMessages(): void {
    const currentTime = this.scene.engine.lastLoopTime || 0;

    this.messages.forEach((m) => {
      if (m.createdAt + 4000 < currentTime) {
        this.messages.splice(this.messages.indexOf(m), 1);
      }
    });
  }
}
