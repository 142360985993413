import { CommandParser, CommandNodeType } from '../commands/CommandParser';
import { CommandValidator } from '../commands/CommandValidator';
import { ParsedCommand } from '../commands/ParsedCommand';
import { PositionInstruction } from '../interfaces/PositionInstruction';
import { checkNever } from '../lib/typeGuards';
import { RadarScene } from '../scenes/RadarScene';

export class TextCommandController {
  public draftCommand: ParsedCommand;
  public openNode: CommandNodeType;
  public isFocussed: boolean = false;
  public hints: string[] = [];
  private commandParser: CommandParser;
  private commandValidator: CommandValidator;
  private container: HTMLDivElement;
  private input: HTMLInputElement;
  private hintInput: HTMLDivElement;
  private hintOffset: number;
  private hintSuffix: string;

  constructor(private scene: RadarScene) {
    this.container = document.createElement('div');
    this.container.className = 'TextCommandControllerContainer';
    this.input = document.createElement('input');
    this.hintInput = document.createElement('div');
    this.hintInput.className = 'HintText';

    this.input.oninput = this.onInput.bind(this);
    this.input.onkeydown = this.onKeyDown.bind(this);
    this.input.onfocus = () => (this.isFocussed = true);
    this.input.onblur = () => (this.isFocussed = false);

    this.container.appendChild(this.input);
    this.container.appendChild(this.hintInput);

    this.commandParser = new CommandParser(scene);
    this.commandValidator = new CommandValidator(scene);
    [this.draftCommand, this.openNode, this.hints, this.hintSuffix] = this.commandParser.parse('');
    this.hintOffset = 0;
  }

  public renderInput(): void {
    document.body.appendChild(this.container);
    this.input.focus();
  }

  public destroyInput(): void {
    document.body.removeChild(this.container);
  }

  public setInputValue(value: string): void {
    this.inputValue = value;
    if (this.inputValue.length > 0) {
      this.input.focus();
    }
  }

  public setLastToken(value: string): void {
    const tokens = this.inputValue.split(' ');
    const lastToken = tokens.pop();
    if (this.openNode === 'set-routing' && lastToken) {
      const parts = lastToken.split('.');
      parts[parts.length - 1] = value;
      this.inputValue = tokens.join(' ') + ` ${parts.join('.')}`;
    } else {
      this.inputValue = tokens.join(' ') + ` ${value}`;
    }
    this.input.focus();
  }

  private onInput(e: Event): void {
    const newVal = (e.target as any).value.toUpperCase();
    this.inputValue = newVal;
  }

  private onKeyDown(e: KeyboardEvent): boolean {
    if (e.code === 'Tab') {
      e.preventDefault();
      this.onTab();
      return false;
    } else if (e.code === 'Enter') {
      e.preventDefault();
      this.onEnter();
      return false;
    } else if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
      e.preventDefault();
      this.bumpHintOffset(e.code === 'ArrowDown');
      return false;
    } else if (
      (e.ctrlKey === true || e.metaKey === true) &&
      (e.code === 'Minus' || e.code === 'Equal')
    ) {
      e.preventDefault();
      return false;
    }

    return true;
  }

  private bumpHintOffset(inc: boolean): void {
    if (this.hints.length <= 1) {
      const tokens = this.inputValue.split(' ');
      const lastToken = tokens[tokens.length - 1];
      const num = Number(lastToken);
      if (lastToken.length > 0 && !isNaN(num)) {
        this.inputValue = tokens.slice(0, -1).join(' ') + ' ' + (num + (inc ? -5 : 5));
      }
      return;
    }

    this.hintOffset += inc ? 1 : -1;

    if (this.hintOffset < 0) {
      this.hintOffset = this.hints.length - 1;
    } else if (this.hintOffset > this.hints.length - 1) {
      this.hintOffset = 0;
    }

    this.hintValue = this.hints[this.hintOffset];
  }

  private onTab(): void {
    if (this.hintValue.length > 0) {
      this.inputValue += `${this.hintValue}${this.hintSuffix}`;
    }
  }

  private onEnter(): void {
    const aircraft = this.draftCommand.aircraft;
    if (!aircraft) {
      return;
    }

    const validatorOutput = this.commandValidator.validate(this.draftCommand);
    if (validatorOutput !== true) {
      return this.scene.radioController.toController(validatorOutput);
    }

    if (this.draftCommand.routing) {
      aircraft.setRouting(this.draftCommand.routing);
    }

    if (this.draftCommand.assignedLocalizer) {
      aircraft.setLocalizer(this.draftCommand.assignedLocalizer);
    }

    if (this.draftCommand.setSpeed) {
      aircraft.setTargetSpeed(this.draftCommand.setSpeed);
    }

    if (this.draftCommand.setAltitude) {
      aircraft.setTargetAltitude(this.draftCommand.setAltitude);
    }

    if (this.draftCommand.assignedHeading !== undefined) {
      aircraft.assignHeading(this.draftCommand.assignedHeading);
    }

    if (this.draftCommand.cancelHold) {
      aircraft.cancelHold();
    }

    if (this.draftCommand.holdAt) {
      aircraft.holdAt(this.draftCommand.holdAt);
    }

    if (this.draftCommand.directTo) {
      aircraft.directTo(this.draftCommand.directTo);
    }

    this.inputValue = `${aircraft.callsign} `;
  }

  private _inputValue: string = '';
  private _hintValue: string = '';

  private set inputValue(newVal: string) {
    if (newVal.length > 100) {
      this.inputValue = this._inputValue;
      return;
    }
    this._inputValue = newVal;
    this.input.value = newVal;

    [this.draftCommand, this.openNode, this.hints, this.hintSuffix] = this.commandParser.parse(
      newVal,
    );
    this.hintOffset = 0;

    if (this.hints.length > 0) {
      this.hintValue = this.hints[0];
    } else {
      this.hintValue = '';
    }

    if (this.draftCommand.aircraft && this.scene.focussedAircraft !== this.draftCommand.aircraft) {
      this.scene.focussedAircraft = this.draftCommand.aircraft;
    } else if (this.scene.focussedAircraft && !this.draftCommand.aircraft && newVal.length === 0) {
      this.scene.focussedAircraft = null;
    }
  }

  private get inputValue(): string {
    return this._inputValue;
  }

  private set hintValue(newVal: string) {
    const padding = new Array(this.inputValue.length + 1).join(' ');
    this._hintValue = newVal;
    this.hintInput.innerText = `${padding}${newVal}`;
  }

  private get hintValue(): string {
    return this._hintValue;
  }
}
