import { SceneI } from '../interfaces/Scene';
import { RenderableI } from '../interfaces/Renderable';
import { ClickableI } from '../interfaces/Clickable';
import { Vector } from '../core/Vector';
import { UIRenderableI } from '../interfaces/UIRenderable';

export class MenuScene implements SceneI {
  public renderableEntities(): RenderableI[] {
    return [];
  }

  public uiRenderableEntities(): UIRenderableI[] {
    return [];
  }

  public clickableEntities(): ClickableI[] {
    return [];
  }

  public tick(n: number): void {}

  public onClick(pos: Vector): boolean {
    return false;
  }
}
