import { RadarScene } from '../scenes/RadarScene';
import { ParsedCommand } from './ParsedCommand';

export class CommandValidator {
  constructor(private scene: RadarScene) {}

  public validate(command: ParsedCommand): true | string {
    if (!command.aircraft) {
      return 'No aircraft specified';
    }

    if (!this.scene.aircrafts.includes(command.aircraft)) {
      return `${command.aircraft.callsign} is no longer in the zone`;
    }

    if (
      command.assignedHeading === undefined &&
      command.assignedLocalizer === undefined &&
      command.routing === undefined &&
      command.setSpeed === undefined &&
      command.setAltitude === undefined &&
      command.holdAt === undefined &&
      command.cancelHold === undefined &&
      command.directTo === undefined
    ) {
      return 'No instructions provided';
    }

    if (command.setSpeed) {
      if (command.setSpeed < 160) {
        return `Unable to reduce speed below 160, ${command.aircraft.callsign}`;
      } else if (command.setSpeed > 270) {
        return `Unable to increase speed above 270, ${command.aircraft.callsign}`;
      }
    }

    if (command.setAltitude) {
      if (command.setAltitude < 2000) {
        return `Unable to desend below 020, ${command.aircraft.callsign}`;
      } else if (command.setAltitude > 9500) {
        return `Unable to climb above 095, ${command.aircraft.callsign}`;
      }
    }

    return true;
  }
}
