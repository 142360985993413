import { Colors } from '../render/Colors';
import { RadarSceneDef } from '../scenes/RadarScene';

const EGLL: RadarSceneDef = {
  range: 3000,
  runways: [
    {
      position: [-6, -20],
      angle: 260,
      length: 60,
      identifier: '26R',
    },
    {
      position: [-6, -20],
      angle: 80,
      length: 60,
      identifier: '08L',
    },
    {
      position: [0, 30],
      angle: 260,
      length: 65,
      identifier: '26L',
    },
    {
      position: [0, 30],
      angle: 80,
      length: 60,
      identifier: '08R',
    },
  ],
  stars: [
    {
      identifier: 'LAM1A',
      labelOffset: 1,
      fixes: ['LOGAN', 'TRIPO', 'BRASO', 'LAM'],
      exitHeading: 205,
    },
    {
      identifier: 'BIG1A',
      fixes: ['LOGAN', 'KOPUL', 'TANET', 'DET', 'BIG'],
      exitHeading: 330,
    },
    {
      identifier: 'BIG2A',
      fixes: ['DVR', 'SANDY', 'TIGER', 'BIG'],
      exitHeading: 330,
    },
    {
      identifier: 'BIG2B',
      fixes: ['ALESO', 'TIGER', 'BIG'],
      exitHeading: 330,
    },
    {
      identifier: 'OCK1A',
      fixes: ['NIGIT', 'OCK'],
      exitHeading: 20,
    },
    {
      identifier: 'OCK2A',
      fixes: ['SOU', 'HAZEL', 'OCK'],
      exitHeading: 20,
    },
    {
      identifier: 'OCK2B',
      fixes: ['SAMSN', 'KATHY', 'HAZEL', 'OCK'],
      exitHeading: 20,
    },
    {
      identifier: 'OCK2C',
      fixes: ['GIBSO', 'ELDER', 'HAZEL', 'OCK'],
      exitHeading: 20,
    },
    {
      identifier: 'OCK3A',
      fixes: ['KENET', 'COM', 'OCK'],
      exitHeading: 20,
    },
    {
      identifier: 'BOV1A',
      fixes: ['BON', 'TOBID', 'WCO', 'BOV'],
      exitHeading: 160,
    },
    {
      identifier: 'BOV2A',
      fixes: ['DONNA', 'SOPIT', 'WCO', 'BOV'],
      exitHeading: 160,
    },
  ],
  fixes: [
    {
      position: [446, -315],
      identifier: 'LAM',
      type: 'vor',
    },
    {
      position: [558, 239],
      identifier: 'BIG',
      type: 'vor',
    },
    {
      position: [922, -622],
      identifier: 'BRASO',
      type: 'fix',
    },
    {
      position: [1625, -764],
      identifier: 'TRIPO',
      type: 'fix',
    },
    {
      position: [2583, -1003],
      identifier: 'LOGAN',
      type: 'fix',
    },
    {
      position: [1175, 298],
      identifier: 'DET',
      type: 'vor',
    },
    {
      position: [1588, -42],
      identifier: 'TANET',
      type: 'fix',
    },
    {
      position: [2340, -508],
      identifier: 'KOPUL',
      type: 'fix',
    },
    {
      position: [954, 665],
      identifier: 'TIGER',
      type: 'fix',
    },
    {
      position: [2198, 431],
      identifier: 'DVR',
      type: 'vor',
    },
    {
      position: [2128, 1531],
      identifier: 'ALESO',
      type: 'fix',
    },
    {
      position: [1622, 606],
      identifier: 'SANDY',
      type: 'fix',
    },
    {
      position: [-517, -412],
      identifier: 'BOV',
      type: 'vor',
    },
    {
      position: [-562, 662],
      identifier: 'OCK',
      type: 'vor',
    },
    {
      position: [-1674, 856],
      identifier: 'NIGIT',
      type: 'fix',
    },
    {
      position: [-805, 1062],
      identifier: 'HAZEL',
      type: 'fix',
    },
    {
      position: [-1332, 1412],
      identifier: 'SOU',
      type: 'vor',
    },
    {
      position: [-193, 1814],
      identifier: 'KATHY',
      type: 'fix',
    },
    {
      position: [-946, 1759],
      identifier: 'ELDER',
      type: 'fix',
    },
    {
      position: [-1776, 2281],
      identifier: 'GIBSO',
      type: 'fix',
    },
    {
      position: [897, 2670],
      identifier: 'SAMSN',
      type: 'fix',
    },

    {
      position: [-1347, 416],
      identifier: 'COM',
      type: 'vor',
    },
    {
      position: [-2242, 306],
      identifier: 'KENET',
      type: 'fix',
    },
    {
      position: [-906, -714],
      identifier: 'WCO',
      type: 'vor',
    },
    {
      position: [-584, -1360],
      identifier: 'SOPIT',
      type: 'fix',
    },
    {
      position: [53, -1989],
      identifier: 'DONNA',
      type: 'fix',
    },
    {
      position: [-1562, -1045],
      identifier: 'TOBID',
      type: 'fix',
    },
    {
      position: [-2659, -1085],
      identifier: 'BON',
      type: 'vor',
    },

    {
      position: [-1391, -320],
      identifier: 'PRIND',
      type: 'fix',
    },
    {
      position: [-1092, 33],
      identifier: 'BONCH',
      type: 'fix',
    },
    {
      position: [-136, 1242],
      identifier: 'TRAVN',
      type: 'fix',
    },
    {
      position: [273, 583],
      identifier: 'CONVE',
      type: 'fix',
    },
    {
      position: [1049, -217],
      identifier: 'BAMBI',
      type: 'fix',
    },
    {
      position: [-136, -1057],
      identifier: 'COURV',
      type: 'fix',
    },
    {
      position: [117, -612],
      identifier: 'NINVE',
      type: 'fix',
    },
  ],
  features: [
    {
      type: 'line-path',
      path: [
        [2580, -1529],
        [2560, -1404],
        [2541, -1357],
        [2521, -1216],
        [2380, -1150],
        [2291, -1021],
        [2197, -923],
        [2048, -822],
        [2021, -771],
        [2037, -740],
        [2107, -751],
        [2044, -626],
        [1916, -556],
        [1896, -544],
        [1849, -544],
        [1779, -595],
        [1732, -568],
        [1642, -556],
        [1583, -494],
        [1521, -478],
        [1447, -478],
        [1431, -462],
        [1474, -443],
        [1572, -455],
        [1603, -455],
        [1642, -509],
        [1701, -470],
        [1712, -419],
        [1693, -310],
        [1732, -244],
        [1712, -166],
        [1669, -142],
        [1611, -107],
        [1541, -37],
        [1458, -25],
        [1357, -48],
        [1294, -33],
        [1291, -13],
        [1142, 17],
        [1048, 13],
        [1029, 95],
        [982, 111],
        [939, 111],
        [900, 111],
        [896, 83],
        [861, 68],
        [814, 83],
        [794, 76],
        [767, 64],
        [748, 44],
        [740, 60],
        [779, 80],
        [806, 99],
        [845, 107],
        [865, 95],
        [892, 111],
        [908, 123],
        [974, 126],
        [1037, 123],
        [1060, 80],
        [1091, 64],
        [1185, 44],
        [1283, 44],
        [1380, 72],
        [1572, 166],
        [1658, 205],
        [1701, 259],
        [1927, 271],
        [2076, 271],
        [2138, 271],
        [2275, 228],
        [2369, 232],
        [2349, 298],
        [2322, 380],
        [2208, 423],
        [2224, 505],
        [2294, 564],
        [2287, 681],
        [2185, 798],
        [2056, 849],
        [1896, 962],
        [1779, 982],
        [1685, 1115],
        [1720, 1150],
        [1728, 1205],
        [1681, 1251],
        [1494, 1197],
        [1423, 1220],
        [1349, 1310],
        [1228, 1361],
        [962, 1412],
        [869, 1517],
        [802, 1583],
        [685, 1572],
        [322, 1443],
        [111, 1408],
        [13, 1439],
        [-275, 1462],
        [-478, 1525],
        [-517, 1576],
        [-556, 1626],
        [-603, 1583],
        [-724, 1529],
        [-876, 1509],
        [-943, 1513],
        [-1021, 1517],
        [-1103, 1466],
        [-1177, 1419],
        [-1271, 1361],
        [-1330, 1302],
        [-1283, 1419],
        [-1310, 1498],
        [-1412, 1517],
        [-1458, 1541],
        [-1501, 1580],
        [-1560, 1623],
        [-1689, 1611],
        [-1791, 1611],
        [-1837, 1638],
        [-1951, 1650],
        [-2041, 1673],
        [-2041, 1736],
        [-2037, 1814],
        [-2068, 1904],
        [-2154, 1904],
        [-2197, 1908],
        [-2220, 1892],
        [-2362, 1849],
      ],
    },
    {
      type: 'polygon-zone',
      path: [
        [-1576, 1732],
        [-1552, 1705],
        [-1525, 1654],
        [-1474, 1654],
        [-1400, 1607],
        [-1302, 1591],
        [-1259, 1533],
        [-1150, 1533],
        [-1130, 1591],
        [-1009, 1595],
        [-943, 1638],
        [-912, 1693],
        [-916, 1755],
        [-896, 1798],
        [-951, 1806],
        [-1025, 1806],
        [-1064, 1830],
        [-1076, 1892],
        [-1173, 1892],
        [-1220, 1900],
        [-1267, 1873],
        [-1302, 1822],
        [-1396, 1818],
        [-1419, 1767],
        [-1466, 1732],
        [-1580, 1751],
      ],
    },
    {
      type: 'circular-zone',
      position: [0, 10],
      radius: 180,
      stubs: [
        {
          width: 170,
          depth: 140,
          angle: 80,
        },
        {
          width: 170,
          depth: 140,
          angle: 260,
        },
      ],
    },
    {
      type: 'tall-obstruction',
      position: [50, -250],
    },
    {
      type: 'tall-obstruction',
      position: [69, -240],
    },
    {
      type: 'tall-obstruction',
      position: [72, -268],
    },
    {
      type: 'tall-obstruction',
      position: [242, 93],
    },
    {
      type: 'tall-obstruction',
      position: [-400, 300],
    },
    {
      type: 'tall-obstruction',
      position: [-420, 320],
    },
    {
      type: 'tall-obstruction',
      position: [-415, 280],
    },
    {
      type: 'tall-obstruction',
      position: [-386, 273],
    },
    {
      type: 'danger-area',
      position: [232, 83],
      width: 50,
      height: 50,
    },
    {
      type: 'danger-area',
      position: [232, 133],
      width: 100,
      height: 50,
    },
    {
      type: 'circular-zone',
      position: [0, 0],
      radius: 500,
      stubs: [],
      style: Colors.mapFeatureRadarRing,
    },
    {
      type: 'circular-zone',
      position: [0, 0],
      radius: 1000,
      stubs: [],
      style: Colors.mapFeatureRadarRing,
    },
    {
      type: 'circular-zone',
      position: [0, 0],
      radius: 1500,
      stubs: [],
      style: Colors.mapFeatureRadarRing,
    },
    {
      type: 'circular-zone',
      position: [0, 0],
      radius: 2000,
      stubs: [],
      style: Colors.mapFeatureRadarRing,
    },
    {
      type: 'circular-zone',
      position: [0, 0],
      radius: 2500,
      stubs: [],
      style: Colors.mapFeatureRadarRing,
    },
    {
      type: 'circular-zone',
      position: [0, 0],
      radius: 3000,
      stubs: [],
    },
  ],
};

export default EGLL;
